import http from "../plugins/axios";

export const getListPointConveersion = async (params) => {
  try {
    const response = await http.get(`/point-conversion`, { params });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getDetailPointConveersion = async (id) => {
  try {
    const response = await http.get(`/point-conversion/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const addPointConveersion = async (payload) => {
  try {
    const response = await http.post(`/point-conversion`, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updatePointConveersion = async (id, payload) => {
  try {
    const response = await http.patch(`/point-conversion/update/${id}`, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};
