import React, { useEffect, useState } from "react";
import { Box, Button, Flex, Group, LoadingOverlay, NumberInput, TextInput } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { NumericFormatCustom } from "../../../plugins/helper";
import { validation } from "../../../plugins/validation";
import { addPointConveersion, updatePointConveersion } from "../../../services/pointConversion";
import { notificationError, notificationSuccess } from "../../ui/Notification";

const defaultVal = {
  nominalAmount: "",
  pointAmount: "",
};

const formValidation = {
  nominalAmount: {
    isError: false,
    message: "",
  },
  pointAmount: {
    isError: false,
    message: "",
  },
};

const FormPointConversion = ({ onCloseModal, dataPointConversion, pointConversionList }) => {
  const isSmallScreen = useMediaQuery("(max-width: 625px)");
  const [formData, setFormData] = useState(defaultVal);
  const [validationForm, setValidationForm] = useState(formValidation);
  const [loadingForm, setLoadingForm] = useState(false);

  console.log(formData);

  useEffect(() => {
    if (dataPointConversion !== null) {
      handleSetForm(dataPointConversion);
    }
    // eslint-disable-next-line
  }, [dataPointConversion]);

  const handleSetForm = async (dataPointConversion) => {
    setLoadingForm(true);
    const dataDetail = {
      nominalAmount: dataPointConversion.nominalAmount,
      pointAmount: dataPointConversion.pointAmount,
    };
    setFormData(dataDetail);
    setLoadingForm(false);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (data) => {
    let methodFunction = null;
    let titleMessageSuccess = "";
    let captionMessageSuccess = "";
    let titleMessageError = "";
    let captionMessageError = "";
    setLoadingForm(true);
    setValidationForm(formValidation);
    const payload = {
      nominalAmount: parseInt(data.nominalAmount),
      pointAmount: data.pointAmount,
    };
    const isError = validation(payload, setValidationForm);
    if (isError) {
      setLoadingForm(false);
      return;
    }
    if (dataPointConversion !== null) {
      methodFunction = updatePointConveersion(dataPointConversion?.id, payload);
      titleMessageSuccess = "Update pengaturan konversi Berhasil";
      captionMessageSuccess = "Anda telah berhasil mengupdate data pengaturan konversi";
      titleMessageError = "Gagal Mengupdate pengaturan konversi";
      captionMessageError = "Silahkan cek kembali form anda";
    } else {
      methodFunction = addPointConveersion(payload);
      titleMessageSuccess = "Tambah pengaturan konversi Berhasil";
      captionMessageSuccess = "Anda telah berhasil menambahkan pengaturan konversi baru";
      titleMessageError = "Gagal Menambahkan pengaturan konversi";
      captionMessageError = "Silahkan cek kembali form anda";
    }
    try {
      const response = await methodFunction;
      if (response) {
        onCloseModal();
        pointConversionList();
        notificationSuccess(titleMessageSuccess, captionMessageSuccess);
      }
    } catch (error) {
      const errorMessage = error.response.data.message;
      notificationError(titleMessageError, `${Object.keys(errorMessage) ? errorMessage : captionMessageError}`);
      Object.values(errorMessage).forEach((el) => {
        Object.keys(formValidation).forEach((element) => {
          if (el.includes(element)) {
            setValidationForm((old) => ({
              ...old,
              [element]: {
                ...old?.[element],
                isError: true,
                message: el,
              },
            }));
          }
        });
      });
    } finally {
      setLoadingForm(false);
    }
  };

  return (
    <Box p={isSmallScreen ? "sm" : 0} style={{ position: "relative", paddingBottom: "60px" }}>
      <Box pos="relative">
        <LoadingOverlay visible={loadingForm} zIndex={1000} overlayProps={{ radius: "sm", blur: 2 }} />

        <Box mb={70}>
          <Box mb="md">
            <TextInput
              component={NumericFormatCustom}
              radius="sm"
              name="nominalAmount"
              value={formData.nominalAmount}
              label="Jumlah Nominal"
              placeholder="Masukkan jumlah nominal"
              error={validationForm.nominalAmount.isError ? `${validationForm.nominalAmount.message}` : ""}
              onChange={handleChange}
              withAsterisk
            />
          </Box>
          <Box mb="md">
            <NumberInput
              radius="sm"
              value={formData.pointAmount}
              label="Poin Didapatkan"
              placeholder="Masukkan point yang didapatkan"
              error={validationForm.pointAmount.isError ? `${validationForm.pointAmount.message}` : ""}
              onChange={(value) => handleChange({ target: { value, name: "pointAmount" } })}
              withAsterisk
            />
          </Box>
        </Box>
      </Box>

      <Box style={{ position: "fixed", bottom: 0, left: 0, width: "100%", background: "white", padding: "20px", zIndex: 1000 }}>
        <Flex justify="flex-end">
          <Group>
            <Button variant="outline" color="#152766" onClick={onCloseModal}>
              Tutup
            </Button>
            <Button variant="filled" color="#152766" onClick={() => handleSubmit(formData)} loading={loadingForm}>
              Simpan
            </Button>
          </Group>
        </Flex>
      </Box>
    </Box>
  );
};

export default FormPointConversion;
