import React, { useEffect, useState } from "react";
import { Badge, Box, Button, Flex, Group, Image, SimpleGrid, Text, Textarea, TextInput } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { Scanner } from "@yudiel/react-qr-scanner";
import { addRedeemCoupon, scanRedeemCoupon } from "../../../services/exchangeCoupon";
import { notificationError, notificationSuccess } from "../../ui/Notification";
import { API_URL } from "../../../plugins/env";
import TextDetail from "../../ui/TextDetail";
import { parseDateLocal } from "../../../plugins/helper";
import { validation } from "../../../plugins/validation";

const defaultVal = {
  couponCodeExchangeId: "",
  description: "",
  code: "",
};

const formValidation = {
  couponCodeExchangeId: {
    isError: false,
    message: "",
  },
  description: {
    isError: false,
    message: "",
  },
  code: {
    isError: false,
    message: "",
  },
};

const FormExchangeCoupon = ({ onCloseModal, dataExchangeCoupon, exchangeCouponList }) => {
  const isSmallScreen = useMediaQuery("(max-width: 625px)");
  const [loadingForm, setLoadingForm] = useState(false);
  const [formData, setFormData] = useState(defaultVal);
  const [validationForm, setValidationForm] = useState(formValidation);
  const [resultQR, setResultQR] = useState(null);
  const [resultQRError, setResultQRError] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    setLoadingForm(true);
    if (dataExchangeCoupon) {
      setResultQR(dataExchangeCoupon);
      setFormData((old) => ({
        ...old,
        description: dataExchangeCoupon?.description,
      }));
    }
    setLoadingForm(false);
    //eslint-disable-next-line
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleScan = async (result) => {
    setLoadingForm(true);
    setFormData((old) => ({
      ...old,
      code: result[0].rawValue,
    }));
    let data = { code: result[0].rawValue };
    await scanRedeemCoupon(data)
      .then((res) => {
        setResultQR(res);
        setFormData(defaultVal);
        setFormData((old) => ({
          ...old,
          couponCodeExchangeId: res.id,
        }));
        notificationSuccess("Berhasil", "Coupon Scanned");
      })
      .catch((err) => {
        setErrorMessage(err.response.data.message);
      });
    setLoadingForm(false);
  };

  const handleSubmitKode = async (data) => {
    let methodFunction = null;
    let titleMessageError = "";
    let captionMessageError = "";
    setLoadingForm(true);
    setValidationForm(formValidation);
    const payload = {
      code: data.code,
    };
    const isError = validation(payload, setValidationForm);
    if (isError) {
      setLoadingForm(false);
      return;
    }
    methodFunction = scanRedeemCoupon(payload);
    titleMessageError = "Gagal Menukarkan Kupon";
    captionMessageError = "Silahkan cek kembali form anda";
    try {
      const res = await methodFunction;
      if (res) {
        setResultQR(res);
        setFormData(defaultVal);
        setFormData((old) => ({
          ...old,
          couponCodeExchangeId: res.id,
        }));
        notificationSuccess("Berhasil", "Coupon Scanned");
      }
    } catch (error) {
      const errorMessage = error.response.data.message;
      notificationError(titleMessageError, `${Object.keys(errorMessage) ? errorMessage : captionMessageError}`);
      Object.values(errorMessage).forEach((el) => {
        Object.keys(formValidation).forEach((element) => {
          if (el.includes(element)) {
            setValidationForm((old) => ({
              ...old,
              [element]: {
                ...old?.[element],
                isError: true,
                message: el,
              },
            }));
          }
        });
      });
    } finally {
      setLoadingForm(false);
    }
  };

  const handleSubmitKupon = async (data) => {
    let methodFunction = null;
    let titleMessageSuccess = "";
    let captionMessageSuccess = "";
    let titleMessageError = "";
    let captionMessageError = "";
    setLoadingForm(true);
    setValidationForm(formValidation);
    const payload = {
      couponCodeExchangeId: data.couponCodeExchangeId,
      // description: data.description,
    };
    if (formData.description !== "") {
      payload.description = data.description;
    }
    const isError = validation(payload, setValidationForm);
    if (isError) {
      setLoadingForm(false);
      return;
    }
    methodFunction = addRedeemCoupon(payload);
    titleMessageSuccess = "Tukar Kupon Berhasil";
    captionMessageSuccess = "Anda telah berhasil menukarkan kupon";
    titleMessageError = "Gagal Menukarkan Kupon";
    captionMessageError = "Silahkan cek kembali form anda";
    try {
      const response = await methodFunction;
      if (response) {
        onCloseModal();
        exchangeCouponList();
        notificationSuccess(titleMessageSuccess, captionMessageSuccess);
      }
    } catch (error) {
      const errorMessage = error.response.data.message;
      notificationError(titleMessageError, `${Object.keys(errorMessage) ? errorMessage : captionMessageError}`);
      Object.values(errorMessage).forEach((el) => {
        Object.keys(formValidation).forEach((element) => {
          if (el.includes(element)) {
            setValidationForm((old) => ({
              ...old,
              [element]: {
                ...old?.[element],
                isError: true,
                message: el,
              },
            }));
          }
        });
      });
    } finally {
      setLoadingForm(false);
    }
  };

  return (
    <Box p={isSmallScreen ? "sm" : 0} style={{ position: "relative", paddingBottom: "60px" }}>
      {resultQR == null && (
        <Box mb={70} px="xl">
          <Box paddingBottom={2}>
            <Text fz={16} fw="bold" mb="sm" mx={3} mr={8} c="red" align="center" justify="center">
              {resultQRError}
            </Text>
            <Text fz={16} fw="bold" mb="sm" mx={3} mr={8} c="red" align="center" justify="center">
              {errorMessage}
            </Text>
            <Box mb="md" px={{ xs: 80, sm: 100, md: 100, lg: 180 }}>
              <Scanner
                onScan={handleScan}
                onError={(error) => setResultQRError(error)}
                containerStyle={{
                  alignItems: "center",
                }}
              />
            </Box>
          </Box>
          <Box mb="md" mt={{ xs: 80, sm: 40, md: 40, lg: 90 }} px={{ xs: 80, sm: 100, md: 100, lg: 100 }}>
            <TextInput radius="sm" name="code" value={formData.code} label="Kode Kupon" placeholder="Masukkan kode kupon" error={validationForm.code.isError ? `${validationForm.code.message}` : ""} onChange={handleChange} />
          </Box>
        </Box>
      )}

      {resultQR !== null && (
        <Box mb={70}>
          <Box align="center" justify="center">
            <Badge mb="sm" color="teal">
              Scan Berhasil
            </Badge>
            <Image key={`${API_URL}/public/${resultQR?.pointCoupon?.image}`} src={`${API_URL}/public/${resultQR?.pointCoupon?.image}`} alt="gamabr kupon" style={{ width: "auto", height: "150px" }} />
          </Box>
          <Box mt="sm"></Box>
          <Box mt="sm" align="center" justify="center">
            <Text fz={14} fw="bold">
              {resultQR?.code || resultQR?.couponCodeExchange.code} | {resultQR?.pointCoupon?.name}
            </Text>
            <Text fz={14} fw="bold">
              {resultQR?.pointCoupon?.description}
            </Text>
          </Box>

          <Box my={10} mt="md">
            <SimpleGrid
              cols={2}
              mx={10}
              spacing="lg"
              breakpoints={[
                { maxWidth: "lg", cols: 2, spacing: "sm" },
                { maxWidth: "md", cols: 2, spacing: "sm" },
                { maxWidth: "sm", cols: 1, spacing: "sm" },
              ]}
            >
              <Box
                style={{
                  borderRight: isSmallScreen ? "0px solid #ededed" : "3px solid #ededed",
                  borderBottom: isSmallScreen ? "1px solid #ededed" : "0px solid #ededed",
                }}
              >
                <TextDetail fz={13} fzVal={13} colorTitle="teal" label="Info Kupon" />
                <TextDetail fz={13} fzVal={13} label="Kode Kupon" value={resultQR?.pointCoupon?.code} />
                <TextDetail fz={13} fzVal={13} label="Nama Kupon" value={resultQR?.pointCoupon?.name} />
                <TextDetail fz={13} fzVal={13} label="Tgl Berlaku" value={`${parseDateLocal(resultQR?.pointCoupon?.startDate)} - ${parseDateLocal(resultQR?.pointCoupon?.endDate)}`} />
              </Box>
              <Box>
                <TextDetail fz={13} fzVal={13} colorTitle="teal" label="Info Customer" />
                <TextDetail fz={13} fzVal={13} label={"Nama"} value={resultQR?.customer?.fullName} />
                <TextDetail fz={13} fzVal={13} label={"No Handphone"} value={resultQR?.customer?.phoneNumber} />
                <TextDetail fz={13} fzVal={13} label={"Member Sejak"} value={parseDateLocal(resultQR?.customer?.createdAt)} />
              </Box>
            </SimpleGrid>
            <Textarea
              radius="md"
              m="sm"
              name="description"
              label="Deskripsi (Opsional)"
              placeholder="Masukkan informasi penukaran kupon"
              value={formData.description}
              error={validationForm.description.isError ? `${validationForm.description.message}` : ""}
              autosize
              minRows={3}
              maxRows={4}
              onChange={handleChange}
              disabled={dataExchangeCoupon ? true : false}
            />
          </Box>
        </Box>
      )}

      <Box style={{ position: "fixed", bottom: 0, left: 0, width: "100%", background: "white", padding: "20px", zIndex: 1000 }}>
        <Flex justify="flex-end">
          <Group>
            <Button variant="outline" color="#152766" onClick={onCloseModal}>
              Tutup
            </Button>
            <Button
              variant="filled"
              color="#152766"
              onClick={() => {
                resultQR == null ? handleSubmitKode(formData) : handleSubmitKupon(formData);
              }}
              loading={loadingForm}
            >
              Simpan
            </Button>
          </Group>
        </Flex>
      </Box>
    </Box>
  );
};

export default FormExchangeCoupon;
