import { Box, Card, Text } from "@mantine/core";
import React from "react";

const CardValue = ({ title, icon, value }) => {
  return (
    <Card shadow="sm" padding="sm" radius="md" withBorder>
      <Box p={6}>
        {icon}
        <Text fz={26} fw="bold">
          {value}
        </Text>
        <Text fz={14}>{title}</Text>
      </Box>
    </Card>
  );
};

export default CardValue;
