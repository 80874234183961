import { Box, Button, Checkbox, Flex, Group, PasswordInput, Popover, Progress, Select, Text, TextInput } from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { useMediaQuery } from "@mantine/hooks";
import React, { useEffect, useState } from "react";
import PasswordRequired from "../../ui/PasswordRequired";
import { IconCheck } from "@tabler/icons-react";
import { validation } from "../../../plugins/validation";
import { addCustomer, updateCustomer } from "../../../services/customer";
import { notificationError, notificationSuccess } from "../../ui/Notification";

const defaultVal = {
  fullName: "",
  email: "",
  phoneNumber: "",
  password: "",
  confirmPassword: "",
  address: "",
  gender: "",
  dateOfBirth: null,
};

const formValidation = {
  fullName: {
    isError: false,
    message: "",
  },
  email: {
    isError: false,
    message: "",
  },
  phoneNumber: {
    isError: false,
    message: "",
  },
  password: {
    isError: false,
    message: "",
  },
  confirmPassword: {
    isError: false,
    message: "",
  },
  address: {
    isError: false,
    message: "",
  },
  gender: {
    isError: false,
    message: "",
  },
  dateOfBirth: {
    isError: false,
    message: "",
  },
};

const requirements = [
  { re: /[0-9]/, label: "Password harus mencantumkan angka" },
  { re: /[a-z]/, label: "Password harus mencantumkan huruf kecil" },
  { re: /[A-Z]/, label: "Password harus mencantumkan huruf besar" },
  { re: /[$&+,:;=?@#|'<>.^*()%!-]/, label: "Password harus mencantumkan simbol" },
];

function getStrength(password) {
  let multiplier = password?.length > 7 ? 0 : 1;

  requirements.forEach((requirement) => {
    if (!requirement.re.test(password)) {
      multiplier += 1;
    }
  });

  return Math.max(100 - (100 / (requirements.length + 1)) * multiplier, 10);
}

const FormCustomer = ({ onCloseModal, dataCustomer, customerList }) => {
  const isSmallScreen = useMediaQuery("(max-width: 625px)");
  const [loadingForm, setLoadingForm] = useState(false);
  const [formData, setFormData] = useState(defaultVal);
  const [validationForm, setValidationForm] = useState(formValidation);
  const [changePassword, setChangePassword] = useState(false);
  const [popoverPassword, setPopoverPassword] = useState(false);
  const checkPassword = requirements.map((val, index) => <PasswordRequired key={index} label={val.label} meets={val.re.test(formData.password)} />);
  const strengthPassword = getStrength(formData?.password);
  console.log(formData);
  console.log(dataCustomer);

  useEffect(() => {
    if (dataCustomer !== null) {
      handleSetForm(dataCustomer);
    }
    // eslint-disable-next-line
  }, [dataCustomer]);

  const handleSetForm = (dataCustomer) => {
    const dataDate = new Date(dataCustomer.profile.dateOfBirth);
    const tgl = new Date(dataCustomer.profile.dateOfBirth).getDate();
    dataDate.setDate(tgl);
    const dataDetail = {
      fullName: dataCustomer.fullName,
      email: dataCustomer.email,
      phoneNumber: dataCustomer.phoneNumber,
      address: dataCustomer.profile.address,
      gender: dataCustomer.profile.gender,
      dateOfBirth: dataDate,
      password: "",
      confirmPassword: "",
    };
    setFormData(dataDetail);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSelectChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };

  const progressBar = Array(4)
    .fill(0)
    .map((_, index) => {
      return (
        <Progress
          styles={{ section: { transitionDuration: "0ms" } }}
          value={Object.values(formData?.password).length > 0 && index === 0 ? 100 : strengthPassword >= ((index + 1) / 4) * 100 ? 100 : 0}
          color={strengthPassword > 80 ? "teal" : strengthPassword > 50 ? "yellow" : "red"}
          key={index}
          size={4}
        />
      );
    });

  const FormPassword = () => {
    // if (setPassword) {
    return (
      <Box>
        <Box mb="md">
          <Popover opened={popoverPassword} position="bottom" width="target" transitionProps={{ transition: "pop" }}>
            <Popover.Target>
              <Box onFocusCapture={() => setPopoverPassword(true)} onBlurCapture={() => setPopoverPassword(false)}>
                <PasswordInput
                  name="password"
                  value={formData.password}
                  placeholder="Masukkan password"
                  label="Password"
                  error={validationForm.password.isError ? `${validationForm.password.message}` : ""}
                  onChange={handleChange}
                  withAsterisk
                />
              </Box>
            </Popover.Target>
            <Popover.Dropdown>
              <Group gap={5} grow mt="xs" mb="md">
                {progressBar}
              </Group>
              <PasswordRequired label="Password harus lebih dari 7 karakter" meets={formData.password.length > 7} />
              {checkPassword}
            </Popover.Dropdown>
          </Popover>
        </Box>
        <Box>
          <PasswordInput
            disabled={formData.password !== "" ? false : true}
            value={formData.confirmPassword}
            name="confirmPassword"
            placeholder="Ulangi password anda"
            label="Konfirmasi Password"
            error={validationForm.confirmPassword.isError ? validationForm.confirmPassword.message : ""}
            onChange={handleChange}
            withAsterisk
          />
        </Box>
        {formData.confirmPassword && (
          <Box mb="md">
            {formData.password !== formData.confirmPassword ? (
              <Text fz={14} mx={3} mr={8} c="red">
                Password tidak cocok!
              </Text>
            ) : (
              <Flex>
                <Text fz={14} mx={3} mr={2} c="green">
                  Password cocok
                </Text>
                <Text fz={14} mx={3} mt={2} c="green">
                  <IconCheck size={18} color="green" />
                </Text>
              </Flex>
            )}
          </Box>
        )}
      </Box>
    );
    // }
  };

  const handleSubmit = async (data) => {
    let methodFunction = null;
    let titleMessageSuccess = "";
    let captionMessageSuccess = "";
    let titleMessageError = "";
    let captionMessageError = "";
    setLoadingForm(true);
    setValidationForm(formValidation);
    const payload = {
      fullName: data.fullName,
      email: data.email,
      phoneNumber: data.phoneNumber,
      address: data.address,
      gender: data.gender,
      dateOfBirth: data.dateOfBirth,
    };
    if (changePassword === true) {
      payload.password = data.password;
      payload.confirmPassword = data.confirmPassword;
    }
    if (!dataCustomer) {
      payload.password = data.password;
      payload.confirmPassword = data.confirmPassword;
    }
    const isError = validation(payload, setValidationForm);
    if (isError) {
      setLoadingForm(false);
      return;
    }
    if (dataCustomer !== null) {
      methodFunction = updateCustomer(dataCustomer?.id, payload);
      titleMessageSuccess = "Update Customer Berhasil";
      captionMessageSuccess = "Anda telah berhasil mengupdate data Customer";
      titleMessageError = "Gagal Mengupdate Customer";
      captionMessageError = "Silahkan cek kembali form anda";
    } else {
      methodFunction = addCustomer(payload);
      titleMessageSuccess = "Tambah Customer Berhasil";
      captionMessageSuccess = "Anda telah berhasil menambahkan Customer baru";
      titleMessageError = "Gagal Menambahkan Customer";
      captionMessageError = "Silahkan cek kembali form anda";
    }
    try {
      const response = await methodFunction;
      if (response) {
        onCloseModal();
        customerList();
        notificationSuccess(titleMessageSuccess, captionMessageSuccess);
      }
    } catch (error) {
      const errorMessage = error.response.data.message;
      notificationError(titleMessageError, `${Object.keys(errorMessage) ? errorMessage : captionMessageError}`);
      Object.values(errorMessage).forEach((el) => {
        Object.keys(formValidation).forEach((element) => {
          if (el.includes(element)) {
            setValidationForm((old) => ({
              ...old,
              [element]: {
                ...old?.[element],
                isError: true,
                message: el,
              },
            }));
          }
        });
      });
    } finally {
      setLoadingForm(false);
    }
  };

  return (
    <Box p={isSmallScreen ? "sm" : 0} style={{ position: "relative", paddingBottom: "60px" }}>
      <Box mb={70}>
        <Box mb="md">
          <TextInput
            radius="sm"
            name="fullName"
            value={formData.fullName}
            label="Nama Lengkap"
            placeholder="Masukkan nama lengkap"
            error={validationForm.fullName.isError ? `${validationForm.fullName.message}` : ""}
            onChange={handleChange}
            withAsterisk
          />
        </Box>
        <Box mb="md">
          <TextInput radius="sm" name="email" value={formData.email} label="Email" placeholder="Masukkan email" error={validationForm.email.isError ? `${validationForm.email.message}` : ""} onChange={handleChange} withAsterisk />
        </Box>
        <Box mb="md">
          <Select label="Gender" withAsterisk mr={8} name="gender" placeholder="Select gender" data={["male", "female"]} onChange={(value) => handleSelectChange("gender", value)} value={formData.gender} />
        </Box>
        <Box mb="md">
          <DatePickerInput valueFormat="DD MMM YYYY" name="dateOfBirth" placeholder="Tanggal lahir" label="Tanggal Lahir" onChange={(value) => handleSelectChange("dateOfBirth", value)} value={formData.dateOfBirth} withAsterisk />
        </Box>
        <Box mb="md">
          <TextInput radius="sm" name="address" value={formData.address} label="Alamat" placeholder="Masukkan alamat" error={validationForm.address.isError ? `${validationForm.address.message}` : ""} onChange={handleChange} withAsterisk />
        </Box>
        <Box mb="md">
          <TextInput
            radius="sm"
            name="phoneNumber"
            value={formData.phoneNumber}
            label="No Telepon/handphone"
            placeholder="Masukkan nomor handphone"
            error={validationForm.phoneNumber.isError ? `${validationForm.phoneNumber.message}` : ""}
            onChange={handleChange}
            withAsterisk
          />
        </Box>
        {dataCustomer && (
          <Box mb="md">
            <Checkbox checked={changePassword} onChange={(e) => setChangePassword(e.currentTarget.checked)} label="Atur Password" />
          </Box>
        )}
        {!dataCustomer && <>{FormPassword()}</>}
        {changePassword === true && <>{FormPassword()}</>}
      </Box>
      <Box style={{ position: "fixed", bottom: 0, left: 0, width: "100%", background: "white", padding: "20px", zIndex: 1000 }}>
        <Flex justify="flex-end">
          <Group>
            <Button variant="outline" color="#152766" onClick={onCloseModal}>
              Tutup
            </Button>
            <Button variant="filled" color="#152766" onClick={() => handleSubmit(formData)} loading={loadingForm}>
              Simpan
            </Button>
          </Group>
        </Flex>
      </Box>
    </Box>
  );
};

export default FormCustomer;
