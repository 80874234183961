import React from "react";
import { Box, Text } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";

const TextDetail = ({ label, value, fz, fzVal, colorTitle }) => {
  const isSmallScreen = useMediaQuery("(max-width: 1125px)");

  return (
    <Box>
      {!isSmallScreen ? (
        <Box py={isSmallScreen ? "xs" : "4px"} gap={5} style={{ display: "flex", flexDirection: isSmallScreen ? "column" : "row", justifyContent: "space-between" }}>
          <Text fz={fz} fw="bold" c={colorTitle}>
            {label}
          </Text>
          <Text mr="md" fz={fzVal}>
            {value}
          </Text>
        </Box>
      ) : (
        <Box mb="sm" ml="sm" px="sm" c={colorTitle}>
          <Box>
            <Text fz={fz} fw="bold">
              {label}
            </Text>
          </Box>
          <Text>{value}</Text>
        </Box>
      )}
    </Box>
  );
};

export default TextDetail;
