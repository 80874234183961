import React, { useEffect, useState } from "react";
import classes from "./transactionPoint.module.css";
import Sidebar from "../../component/ui/AppShell/sidebar";
import { Box, Button, Card, Flex, Grid, Modal, Pagination, ScrollArea, Text, TextInput, rem } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { IconCopyPlus, IconSearch } from "@tabler/icons-react";
import TableSkeleton from "../../component/ui/TableSkeleton";
import TableTransactionPoint from "../../component/pages/transactionPoint/TableTransactionPoint";
import FormTransaction from "../../component/pages/transactionPoint/FormTransaction";
import { TABLE_DEFINITION } from "../../plugins/labelTable";
import { getListTransactionPoint } from "../../services/transactionPoint";
import FormDeleteTransactionPoint from "../../component/pages/transactionPoint/FormDelete";
import { DatePickerInput } from "@mantine/dates";
import { getFirstDayOfMonth, getLastDayOfMonth } from "../../plugins/helper";

const param = {
  orderBy: "createdAt",
  order: "DESC",
  take: 10,
  skip: 0,
  keywords: "",
  fromDate: getFirstDayOfMonth(),
  toDate: getLastDayOfMonth(),
};

const defaultVal = {
  fromDate: getFirstDayOfMonth(),
  toDate: getLastDayOfMonth(),
};

const TransactionPoint = () => {
  const isSmallScreen = useMediaQuery("(max-width: 625px)");
  const [formData, setFormData] = useState(defaultVal);
  const [params, setParams] = useState(param);
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [detailData, setDetailData] = useState(null);
  const [transactionPointList, setTransactionPointList] = useState([]);
  const [count, setCount] = useState(0);
  // console.log(transactionPointList);

  const handleChange = (name, value) => {
    setParams({ ...params, [name]: value });
    setFormData({ ...formData, [name]: value });
  };

  const handleGetListTransactionPoint = async () => {
    setLoading(true);
    try {
      const response = await getListTransactionPoint(params);
      const dataTransactionPoint = response.data;
      setTransactionPointList(dataTransactionPoint);
      setCount(response.count);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    handleGetListTransactionPoint();
    // eslint-disable-next-line
  }, [params]);

  const handleSearchChange = (e) => {
    const params = {
      orderBy: "createdAt",
      order: "DESC",
      take: 10,
      skip: 0,
      keywords: e.target.value,
      fromDate: formData.fromDate,
      toDate: formData.toDate,
    };
    setParams(params);
  };

  const handleChangePage = (e) => {
    const params = {
      orderBy: "createdAt",
      order: "DESC",
      take: 10,
      skip: (e - 1) * 10,
      keywords: "",
      fromDate: formData.fromDate,
      toDate: formData.toDate,
    };
    setParams(params);
  };

  const onCloseModal = () => {
    setOpenModal(false);
    setDetailData(null);
  };

  const onCloseModalDelete = () => {
    setOpenModalDelete(false);
    setDetailData(null);
  };

  const actions = {
    edit: (val) => {
      setOpenModal(true);
      setDetailData(val);
    },
    delete: (val) => {
      setOpenModalDelete(true);
      setDetailData(val);
    },
  };

  const handleAction = (val, type) => {
    return actions[type](val);
  };

  return (
    <Sidebar>
      <Box className={classes.body}>
        <Box py={isSmallScreen ? "xs" : "lg"} style={{ display: "flex", flexDirection: isSmallScreen ? "column" : "row", justifyContent: "space-between" }}>
          <Flex>
            <Text className={classes.titlePage} ml={isSmallScreen ? "0px" : "sm"}>
              Transaksi Poin
            </Text>
          </Flex>
          <Flex px={isSmallScreen ? "0px" : "sm"} mt={isSmallScreen ? 10 : 0} style={{ display: "flex", flexDirection: isSmallScreen ? "column" : "row", justifyContent: "space-between" }}>
            <TextInput
              radius="md"
              mr={isSmallScreen ? "0px" : "sm"}
              mb={isSmallScreen ? "sm" : "0px"}
              fullWidth
              leftSectionPointerEvents="none"
              leftSection=<IconSearch style={{ width: rem(16), height: rem(16) }} />
              placeholder="Cari customer. . ."
              name="search"
              onChange={handleSearchChange}
            />
            <Button className={classes.button} radius="md" fullWidth={isSmallScreen} leftSection={<IconCopyPlus size={14} />} color="#152766" onClick={() => setOpenModal(true)}>
              Tambah Transaksi Poin
            </Button>
          </Flex>
        </Box>
        <Box>
          <Card shadow="sm" padding="sm" radius="md" withBorder>
            <Box mb="md" mt="sm" px="sm">
              <Grid>
                <Grid.Col span={{ base: 6, md: 6 }}>
                  <DatePickerInput value={params.fromDate} valueFormat="DD MMM YYYY" name="fromDate" placeholder="Tanggal dimulai" label="Tanggal Dimulai" onChange={(value) => handleChange("fromDate", value)} />
                </Grid.Col>
                <Grid.Col span={{ base: 6, md: 6 }}>
                  <DatePickerInput value={params.toDate} valueFormat="DD MMM YYYY" name="toDate" placeholder="Tanggal berakhir" label="Tanggal Berakhir" onChange={(value) => handleChange("toDate", value)} />
                </Grid.Col>
              </Grid>
            </Box>
          </Card>
        </Box>
        <Box my={10}>
          {loading ? (
            <TableSkeleton total={5} />
          ) : (
            <Card shadow="sm" padding="sm" radius="md" withBorder>
              <TableTransactionPoint label={TABLE_DEFINITION.transactionPoint} data={transactionPointList} countData={params.skip} actionMethod={handleAction} />
            </Card>
          )}
        </Box>
        <Flex justify="end">
          <Pagination onChange={handleChangePage} total={Math.ceil(count / params.take) || 0} color="#152766" radius="lg" />
        </Flex>
      </Box>

      <Modal
        fullScreen={isSmallScreen}
        opened={openModal}
        onClose={onCloseModal}
        centered
        closeOnClickOutside={false}
        size="lg"
        title={
          <Text fw="Bold" p={isSmallScreen ? "sm" : 0}>
            {detailData ? "Edit Transaksi" : "Tambah Transaksi"}
          </Text>
        }
        scrollAreaComponent={ScrollArea.Autosize}
        withCloseButton={isSmallScreen ? true : false}
      >
        <FormTransaction onCloseModal={onCloseModal} dataTransactionPoint={detailData} transactionPointList={handleGetListTransactionPoint} />
      </Modal>
      <Modal
        fullScreen={isSmallScreen}
        opened={openModalDelete}
        onClose={onCloseModalDelete}
        centered
        closeOnClickOutside={false}
        size="lg"
        title={
          <Text fw="Bold" p={isSmallScreen ? "sm" : 0}>
            Hapus Transaksi{" "}
          </Text>
        }
        scrollAreaComponent={ScrollArea.Autosize}
        withCloseButton={isSmallScreen ? true : false}
      >
        <FormDeleteTransactionPoint onCloseModal={onCloseModalDelete} dataTransactionPoint={detailData} transactionPointList={handleGetListTransactionPoint} />
      </Modal>
    </Sidebar>
  );
};

export default TransactionPoint;
