import { Badge, Box, Card, Flex, Text } from "@mantine/core";
import React from "react";

const CardValue2 = ({ title, value, persentage }) => {
  return (
    <Card shadow="sm" padding="lg" radius="md" withBorder mb="md">
      <Box p={10} align="center" justify="center">
        <Text fz={16}>{title}</Text>
        <Flex align="center" justify="center">
          <Text fz={46} fw="bold" mr="md">
            {value}
          </Text>
          {persentage && (
            <Badge size="lg" color="lime.4" mt="lg">
              {persentage}
            </Badge>
          )}
        </Flex>
      </Box>
    </Card>
  );
};

export default CardValue2;
