import { IconAward, IconCashRegister, IconExchange, IconGift, IconLayoutDashboard, IconObjectScan, IconTicket, IconUser, IconUsers } from "@tabler/icons-react";
import Authentication from "./pages/authentication";
import Dashboard from "./component/pages/dashboard";
import { MENU_PERMISSION } from "./plugins/permissions";
import Customer from "./pages/customer";
import MemberLevel from "./pages/memberLevel";
import PointConversion from "./pages/pointConversion";
import KuponPoin from "./pages/kupon";
import TransactionPoint from "./pages/transactionPoint";
import Gift from "./pages/gift";
import ExchangeCoupon from "./pages/exchangeCoupon";
import DetailCustomer from "./pages/customer/DetailCustomer";
import VerificationCustomer from "./pages/verificationCustomer";
import Profile from "./pages/profile";
import AuthenticationCustomer from "./pages/authenticationCustomer";

const routes = [
  {
    name: "dashboard",
    route: "/",
    hasChildren: false,
    component: <Dashboard />,
    icon: <IconLayoutDashboard stroke={2.5} size={20} />,
    type: "route",
    permission: MENU_PERMISSION.dashboard,
  },
  {
    name: "customer",
    route: "/customer",
    hasChildren: true,
    children: [
      {
        name: "index",
        route: null,
        component: <Customer />,
      },
      {
        name: "detail customer",
        route: "/customer/:id",
        component: <DetailCustomer />,
      },
    ],
    icon: <IconUsers stroke={2.5} size={20} />,
    type: "route",
    permission: MENU_PERMISSION.customer,
  },
  {
    name: "level member",
    route: "/level-member",
    hasChildren: false,
    component: <MemberLevel />,
    icon: <IconAward stroke={2.5} size={20} />,
    type: "route",
    permission: MENU_PERMISSION.memberLevel,
  },
  {
    name: "pengaturan konversi",
    route: "/pengaturan-konversi",
    hasChildren: false,
    component: <PointConversion />,
    icon: <IconExchange stroke={2.5} size={20} />,
    type: "route",
    permission: MENU_PERMISSION.conversionSetting,
  },
  {
    name: "kupon",
    route: "/kupon",
    hasChildren: false,
    component: <KuponPoin />,
    icon: <IconTicket stroke={2.5} size={20} />,
    type: "route",
    permission: MENU_PERMISSION.pointCoupon,
  },
  {
    name: "hadiah",
    route: "/hadiah",
    hasChildren: false,
    component: <Gift />,
    icon: <IconGift stroke={2.5} size={20} />,
    type: "route",
    permission: MENU_PERMISSION.gift,
  },
  {
    name: "Transaksi Poin",
    route: "/transaksi-poin",
    hasChildren: false,
    component: <TransactionPoint />,
    icon: <IconCashRegister stroke={2.5} size={20} />,
    type: "route",
    permission: MENU_PERMISSION.transactionPoint,
  },
  {
    name: "Tukar Kupon",
    route: "/tukar-kupon",
    hasChildren: false,
    component: <ExchangeCoupon />,
    icon: <IconObjectScan stroke={2.5} size={20} />,
    type: "route",
    permission: MENU_PERMISSION.couponExchange,
  },
  {
    name: "Profil",
    route: "/profil",
    hasChildren: false,
    component: <Profile />,
    icon: <IconUser stroke={2.5} size={20} />,
    type: "route",
    // permission: MENU_PERMISSION.couponExchange,
  },
];

const routesAuth = [
  {
    name: "authentication",
    route: "/authentication/sign-in",
    hasChildren: false,
    component: <Authentication />,
  },
];

const routesAuthVerification = [
  {
    name: "authentication",
    route: "/authentication/verification/:token",
    hasChildren: false,
    component: <VerificationCustomer />,
  },
];

const routesAuthCustomer = [
  {
    name: "authentication",
    route: "/authentication/customer/:token",
    hasChildren: false,
    component: <AuthenticationCustomer />,
  },
];

export { routes, routesAuth, routesAuthVerification, routesAuthCustomer };
