import http from "../plugins/axios";

export const getListCoupon = async (params) => {
  try {
    const response = await http.get(`/point-coupon`, { params });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getDetailCoupon = async (id) => {
  try {
    const response = await http.get(`/point-coupon/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const addCoupon = async (payload) => {
  try {
    const response = await http.post(`/point-coupon`, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateCoupon = async (id, payload) => {
  try {
    const response = await http.patch(`/point-coupon/update/${id}`, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteCoupon = async (id) => {
  try {
    const response = await http.delete(`/point-coupon/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
};
