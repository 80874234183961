import React, { useEffect, useState } from "react";
import classes from "./pointConversion.module.css";
import Sidebar from "../../component/ui/AppShell/sidebar";
import { Box, Button, Card, Flex, Modal, Pagination, ScrollArea, Text } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { IconCopyPlus } from "@tabler/icons-react";
import TableSkeleton from "../../component/ui/TableSkeleton";
import TablePointConversion from "../../component/pages/pointConversion/TablePointConversion";
import FormPointConversion from "../../component/pages/pointConversion/FormPointConversion";
import { TABLE_DEFINITION } from "../../plugins/labelTable";
import { getListPointConveersion } from "../../services/pointConversion";

const param = {
  orderBy: "createdAt",
  order: "DESC",
  take: 10,
  skip: 0,
};

const PointConversion = () => {
  const isSmallScreen = useMediaQuery("(max-width: 625px)");
  const [params, setParams] = useState(param);
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [detailData, setDetailData] = useState(null);
  const [pointConversionList, setPointConversionList] = useState([]);
  const [count, setCount] = useState(0);

  const handleGetListPointConversion = async () => {
    setLoading(true);
    try {
      const response = await getListPointConveersion(params);
      const dataPointConversion = response.data;
      setPointConversionList(dataPointConversion);
      setCount(response.count);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    handleGetListPointConversion();
    // eslint-disable-next-line
  }, [params]);

  const handleChangePage = (e) => {
    const params = {
      orderBy: "createdAt",
      order: "DESC",
      take: 10,
      skip: (e - 1) * 10,
    };
    setParams(params);
  };

  const onCloseModal = () => {
    setOpenModal(false);
    setDetailData(null);
  };

  const actions = {
    edit: (val) => {
      setOpenModal(true);
      setDetailData(val);
    },
  };

  const handleAction = (val, type) => {
    return actions[type](val);
  };

  return (
    <Sidebar>
      <Box className={classes.body}>
        <Box py={isSmallScreen ? "xs" : "lg"} style={{ display: "flex", flexDirection: isSmallScreen ? "column" : "row", justifyContent: "space-between" }}>
          <Flex>
            <Text className={classes.titlePage} ml={isSmallScreen ? "0px" : "sm"}>
              Konversi Poin
            </Text>
          </Flex>
          <Flex px={isSmallScreen ? "0px" : "sm"} mt={isSmallScreen ? 10 : 0} style={{ display: "flex", flexDirection: isSmallScreen ? "column" : "row", justifyContent: "space-between" }}>
            <Button className={classes.button} radius="md" fullWidth={isSmallScreen} leftSection={<IconCopyPlus size={14} />} color="#152766" onClick={() => setOpenModal(true)}>
              Tambah Konversi
            </Button>
          </Flex>
        </Box>
      </Box>
      <Box my={10}>
        {loading ? (
          <TableSkeleton total={5} />
        ) : (
          <Card shadow="sm" padding="sm" radius="md" withBorder>
            <TablePointConversion label={TABLE_DEFINITION.pointConversion} data={pointConversionList} countData={params.skip} actionMethod={handleAction} />
          </Card>
        )}
      </Box>
      <Flex justify="end">
        <Pagination onChange={handleChangePage} total={Math.ceil(count / params.take) || 0} color="#152766" radius="lg" />
      </Flex>

      <Modal
        fullScreen={isSmallScreen}
        opened={openModal}
        onClose={onCloseModal}
        centered
        closeOnClickOutside={false}
        size="lg"
        title={
          <Text fw="Bold" p={isSmallScreen ? "sm" : 0}>
            {detailData ? "Edit Konversi" : "Tambah Konversi"}
          </Text>
        }
        scrollAreaComponent={ScrollArea.Autosize}
        withCloseButton={isSmallScreen ? true : false}
      >
        <FormPointConversion onCloseModal={onCloseModal} dataPointConversion={detailData} pointConversionList={handleGetListPointConversion} />
      </Modal>
    </Sidebar>
  );
};

export default PointConversion;
