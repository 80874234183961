import http from "../plugins/axios";

export const getListMemberLevel = async (params) => {
  try {
    const response = await http.get(`/member-level`, { params });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getDetailMemberLevel = async (id) => {
  try {
    const response = await http.get(`/member-level/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const addMemberLevel = async (payload) => {
  try {
    const response = await http.post(`/member-level`, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateMemberLevel = async (id, payload) => {
  try {
    const response = await http.patch(`/member-level/update/${id}`, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteMemberLevel = async (id) => {
  try {
    const response = await http.delete(`/member-level/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
};
