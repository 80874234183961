import React from "react";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import { AppShell, Box, Flex, ScrollArea, Text } from "@mantine/core";
import Cookies from "universal-cookie";
import { IconLogout, IconUser } from "@tabler/icons-react";
import ProtectedRoute from "../../../../middleware/protectedRoute";
import classes from "../sidebarMenu/sidebarMenu.module.css";
import Header from "../header";
import SidebarMenu from "../sidebarMenu";
import { useNavigate } from "react-router-dom";
import { logout } from "../../../../services/auth";

const Sidebar = ({ children }) => {
  const cookies = new Cookies();
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width: 625px)");
  const domain = window.location.hostname;
  const [openSidebarDesktop, { toggle: toggleDesktop }] = useDisclosure(true);
  const [openSidebarMobile, { toggle: toggleMobile }] = useDisclosure();

  const handleLogout = async () => {
    await logout().then((res) => {
      console.log(res);
      cookies.remove("authData", { path: "/", domain: domain === "localhost" ? "localhost" : "tandeem.co.id" });
      cookies.remove("token_account_tandeem", { path: "/", domain: domain === "localhost" ? "localhost" : "tandeem.co.id" });
      cookies.remove("refreshToken_account_tandeem", { path: "/", domain: domain === "localhost" ? "localhost" : "tandeem.co.id" });
      setTimeout(() => {
        cookies.remove("authData", { path: "/", domain: domain === "localhost" ? "localhost" : "tandeem.co.id" });
        cookies.remove("token_account_tandeem", { path: "/", domain: domain === "localhost" ? "localhost" : "tandeem.co.id" });
        cookies.remove("refreshToken_account_tandeem", { path: "/", domain: domain === "localhost" ? "localhost" : "tandeem.co.id" });
        window.location.href = "/authentication/sign-in";
      }, 200);
    });
  };

  return (
    <AppShell
      header={{ height: { base: 60, md: 60, lg: 70 } }}
      navbar={{
        width: { base: 200, md: 250, lg: 280 },
        breakpoint: "sm",
        collapsed: { mobile: !openSidebarMobile, desktop: !openSidebarDesktop },
      }}
      padding="md"
    >
      <AppShell.Header>
        <Header mobileToggle={toggleMobile} desktopToggle={toggleDesktop} openedMobile={openSidebarMobile} openedDesktop={openSidebarDesktop} />
      </AppShell.Header>
      <AppShell.Navbar p="md">
        <AppShell.Section grow component={ScrollArea}>
          <ProtectedRoute>
            <SidebarMenu />
          </ProtectedRoute>
        </AppShell.Section>
        <AppShell.Section>
          <Box p="lg" style={{ borderTop: "1px solid gainsboro" }} className={classes.link}>
            <Flex
              ml={12}
              align="center"
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate(`/profil`);
              }}
            >
              <span className={classes.linkIcon} mt="md">
                <IconUser stroke={2.5} size={20} c="#152766" mt="md" />
              </span>
              <Text fz={13} fw={700} c="#152766">
                Profile
              </Text>
            </Flex>
          </Box>
        </AppShell.Section>
        {isMobile && (
          <AppShell.Section>
            <Box p="lg" style={{ borderTop: "1px solid gainsboro" }} className={classes.link}>
              <Flex ml={12} align="center" style={{ cursor: "pointer" }} onClick={handleLogout}>
                <span className={classes.linkIcon} mt="md">
                  <IconLogout stroke={2.5} size={20} c="#152766" mt="md" />
                </span>
                <Text fz={13} fw={700} c="#152766">
                  Log out
                </Text>
              </Flex>
            </Box>
          </AppShell.Section>
        )}
      </AppShell.Navbar>
      <AppShell.Main className={classes.sectionMenu}>{children}</AppShell.Main>
    </AppShell>
  );
};

export default Sidebar;
