import http from "../plugins/axios";

export const getListTransactionPointHIstory = async (take, skip, customerId, type) => {
  try {
    const response = await http.get(`/point-transaction-history?orderBy=createdAt&order=DESC&take=${take || 10}&skip=${skip || 0}&customerId=${customerId || ""}&type=${type || ""}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
