import React, { useState } from "react";
import classes from "./verificationCustomer.module.css";
import { Badge, Box, Button, Card, Group, rem, Text } from "@mantine/core";
import { IconMailCheck } from "@tabler/icons-react";
import { useMediaQuery } from "@mantine/hooks";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../../plugins/env";

const VerificationCustomer = () => {
  let { token } = useParams();
  const redirect = useNavigate();
  const isMobile = useMediaQuery("(max-width: 430px)");
  const [errMessage, setErrMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const valueBeforeSource = token.split("&source=")[0];

  const handleVerify = async () => {
    setLoading(true);
    await axios
      .post(`${API_URL}/verification-email/verify/${valueBeforeSource}`)
      .then(() => {
        setIsSuccess(true);
        setTimeout(() => {
          redirect("/authentication/sign-in");
        }, 5000);
      })
      .catch((err) => {
        setErrMessage(err?.response?.data?.message || "");
      });
    setLoading(false);
  };

  return (
    <Box className={classes.body}>
      <Box className={classes.card}>
        <Card shadow="lg" padding="lg" radius="md" w={isMobile ? 280 : 400}>
          <Box className={classes.logo}>
            <IconMailCheck size={44} color={isSuccess ? "teal" : "black"} />
          </Box>
          {isSuccess ? (
            <Text mt="sm" fw={800} fz={20} className={classes.closeText} c="teal">
              Verifikasi Akun berhasil, Anda akan diarahkan ke halaman login...
            </Text>
          ) : (
            <>
              <Group className={classes.textGroup} mt="md" mb="xs" justify="center">
                <Text fw={800} fz={20} className={classes.closeText}>
                  Verifikasi Akun
                </Text>
                <Text className={classes.closeText} fw={400} fz={14} mb="md">
                  Mohon klik tombol di bawah untuk memverifikasi akun Anda
                </Text>
              </Group>
              {errMessage && (
                <Box mb="lg" ta="center">
                  <Badge size="md" variant="outline" color="red" radius="lg" fw={600} border={`${rem(1)} solid red`}>
                    {errMessage}
                  </Badge>
                </Box>
              )}
              <Box>
                <Box>
                  <Group grow>
                    <Button className={classes.button} variant="filled" radius="sm" size="md" color="#152766" type="submit" onClick={handleVerify} loading={loading}>
                      Verifikasi
                    </Button>
                  </Group>
                </Box>
              </Box>
            </>
          )}
        </Card>
      </Box>
    </Box>
  );
};

export default VerificationCustomer;
