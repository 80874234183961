import http from "../plugins/axios";

export const getListTransactionPoint = async (params) => {
  try {
    const response = await http.get(`/point-transaction`, { params });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getListTransactionPointCustomer = async (take, skip, customerId, keywords) => {
  try {
    const response = await http.get(`/point-transaction?orderBy=createdAt&order=DESC&take=${take || 10}&skip=${skip || 0}&customerId=${customerId || ""}&keywords=${keywords || ""}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getDetailTransactionPoint = async (id) => {
  try {
    const response = await http.get(`/point-transaction/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const addTransactionPoint = async (payload) => {
  try {
    const response = await http.post(`/point-transaction`, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateTransactionPoint = async (id, payload) => {
  try {
    const response = await http.patch(`/point-transaction/update/${id}`, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteTransactionPoint = async (id) => {
  try {
    const response = await http.delete(`/point-transaction/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
};
