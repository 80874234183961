import React from "react";
import classes from "./profile.module.css";
import Sidebar from "../../component/ui/AppShell/sidebar";
import { useMediaQuery } from "@mantine/hooks";
import { Avatar, Box, Button, Card, Divider, Flex, Grid, Text } from "@mantine/core";
import { useSelector } from "react-redux";
import Cookies from "universal-cookie";

const Profile = () => {
  const cookies = new Cookies();
  const domain = window.location.hostname;

  const isSmallScreen = useMediaQuery("(max-width: 625px)");
  const dataUser = useSelector((state) => state.auth.dataUser);
  console.log(dataUser);

  const handleLogout = async () => {
    // await LogoutSSO().then((res) => {
    //   console.log(res);
    cookies.remove("authData", { path: "/", domain: domain === "localhost" ? "localhost" : "tandeem.co.id" });
    cookies.remove("token_account_tandeem", { path: "/", domain: domain === "localhost" ? "localhost" : "tandeem.co.id" });
    cookies.remove("refreshToken_account_tandeem", { path: "/", domain: domain === "localhost" ? "localhost" : "tandeem.co.id" });
    setTimeout(() => {
      cookies.remove("authData", { path: "/", domain: domain === "localhost" ? "localhost" : "tandeem.co.id" });
      cookies.remove("token_account_tandeem", { path: "/", domain: domain === "localhost" ? "localhost" : "tandeem.co.id" });
      cookies.remove("refreshToken_account_tandeem", { path: "/", domain: domain === "localhost" ? "localhost" : "tandeem.co.id" });
      window.location.href = "/authentication/sign-in";
    }, 200);
    // });
  };

  return (
    <Sidebar>
      <Box className={classes.body}>
        <Box py={isSmallScreen ? "xs" : "lg"} style={{ display: "flex", flexDirection: isSmallScreen ? "column" : "row", justifyContent: "space-between" }}>
          <Flex>
            <Text className={classes.titlePage} ml={isSmallScreen ? "0px" : "sm"}>
              Profile
            </Text>
          </Flex>
        </Box>
      </Box>
      <Box my={10}>
        <Card shadow="sm" padding="sm" radius="md" withBorder>
          <Box py={isSmallScreen ? "xs" : "lg"} px="md" style={{ display: "flex", flexDirection: isSmallScreen ? "column" : "row", justifyContent: "space-between" }}>
            <Flex>
              <Avatar size="xl" mt="xs" />
              <Box p="md">
                <Text fz={18} fw="bold">
                  {dataUser?.fullName}
                </Text>
                <Text fz={14}>{dataUser?.email}</Text>
                <Text fz={14}>{dataUser?.organization}</Text>
              </Box>
            </Flex>
            <Flex px={isSmallScreen ? "0px" : "lg"} mt={isSmallScreen ? 10 : 34} style={{ display: "flex", flexDirection: isSmallScreen ? "column" : "row", justifyContent: "space-between" }}>
              <Button onClick={handleLogout} radius="md" fullWidth={isSmallScreen} color="#152766">
                Logout
              </Button>
            </Flex>
          </Box>
          <Divider />
          <Box py={isSmallScreen ? "md" : "lg"} px="md">
            <Grid>
              <Grid.Col span={{ base: 12, xs: 6 }}>
                <Text fz={12} fw="bold">
                  {" "}
                  Nama
                </Text>
                <Text fz={14}>{dataUser?.fullName}</Text>
              </Grid.Col>
              <Grid.Col span={{ base: 12, xs: 6 }}>
                <Text fz={12} fw="bold">
                  {" "}
                  Email
                </Text>
                <Text fz={14}>{dataUser?.email}</Text>
              </Grid.Col>
            </Grid>
            <Grid my={10}>
              <Grid.Col span={{ base: 12, xs: 6 }}>
                <Text fz={12} fw="bold">
                  {" "}
                  Role
                </Text>
                <Text fz={14}>{dataUser?.role.name}</Text>
              </Grid.Col>
            </Grid>
          </Box>
        </Card>
      </Box>
    </Sidebar>
  );
};

export default Profile;
