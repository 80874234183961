import React, { useEffect, useState } from "react";
import { useMediaQuery } from "@mantine/hooks";
import { Autocomplete, Box, Button, Flex, Group, LoadingOverlay, Text, TextInput } from "@mantine/core";
import { NumericFormatCustom, parseDate } from "../../../plugins/helper";
import { DatePickerInput } from "@mantine/dates";
import { getListCustomer } from "../../../services/customer";
import { notificationError, notificationSuccess } from "../../ui/Notification";
import { addTransactionPoint, updateTransactionPoint } from "../../../services/transactionPoint";
import { validation } from "../../../plugins/validation";
import FormInfo from "../../ui/FormInfo";

const defaultVal = {
  phoneNumber: "",
  nominalAmount: "",
  transactionCode: "",
  transactionDate: new Date(),
};

const formValidation = {
  phoneNumber: {
    isError: false,
    message: "",
  },
  nominalAmount: {
    isError: false,
    message: "",
  },
  transactionCode: {
    isError: false,
    message: "",
  },
  transactionDate: {
    isError: false,
    message: "",
  },
};

const FormTransaction = ({ onCloseModal, dataTransactionPoint, transactionPointList }) => {
  const isSmallScreen = useMediaQuery("(max-width: 625px)");
  const [loadingForm, setLoadingForm] = useState(false);
  const [formData, setFormData] = useState(defaultVal);
  const [validationForm, setValidationForm] = useState(formValidation);
  const [selectedCustomerId, setSelectedCustomerId] = useState("");
  const [selectedcustomerData, setSelectedcustomerData] = useState(null);
  const [customerList, setcustomerList] = useState([]);

  console.log(formData);
  // console.log(dataTransactionPoint);

  const handleGetListCustomer = async () => {
    setLoadingForm(true);
    try {
      const response = await getListCustomer();
      const dataCustomer = response.data;
      const mappingCustomer = [
        { value: "", label: "Search phone number" },
        ...dataCustomer.map((val) => ({
          value: val?.id?.toString(),
          label: `${val?.phoneNumber}`,
          name: val?.fullName,
          email: val?.email,
        })),
      ];
      setcustomerList(mappingCustomer);
    } catch (error) {
      console.log(error);
    }
    setLoadingForm(false);
  };

  useEffect(() => {
    handleGetListCustomer();
    // eslint-disable-next-line
  }, []);

  const renderAutocompleteOption = ({ option }) => (
    <Group gap="sm">
      <div>
        <Text size="sm">{option?.label}</Text>
        <Text size="xs" opacity={0.5}>
          {option?.name}
        </Text>
      </div>
    </Group>
  );

  useEffect(() => {
    if (dataTransactionPoint !== null) {
      handleSetForm(dataTransactionPoint);
    }
    // eslint-disable-next-line
  }, [dataTransactionPoint]);

  const handleSetForm = async (dataTransactionPoint) => {
    setLoadingForm(true);

    const dataDate = new Date(dataTransactionPoint.transactionDate);
    const tgl = new Date(dataTransactionPoint.transactionDate).getDate();
    dataDate.setDate(tgl);

    const dataDetail = {
      phoneNumber: selectedCustomerId,
      nominalAmount: dataTransactionPoint.nominalAmount,
      transactionCode: dataTransactionPoint.transactionCode,
      transactionDate: dataDate,
    };
    setSelectedCustomerId(dataTransactionPoint?.customer?.phoneNumber.toString());
    setFormData(dataDetail);
    setLoadingForm(false);
  };

  const handleCustomerChange = (value) => {
    console.log(value);
    setSelectedCustomerId(value);
    const selectedCustomerData = customerList.find((customer) => customer.label === value);
    setSelectedcustomerData(selectedCustomerData);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleDateChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (data) => {
    let methodFunction = null;
    let titleMessageSuccess = "";
    let captionMessageSuccess = "";
    let titleMessageError = "";
    let captionMessageError = "";
    setLoadingForm(true);
    setValidationForm(formValidation);
    const payload = {
      phoneNumber: selectedCustomerId,
      nominalAmount: parseInt(data.nominalAmount),
      // transactionCode: data.transactionCode,
      transactionDate: parseDate(data.transactionDate),
    };
    if (formData.transactionCode !== "") {
      payload.transactionCode = data.transactionCode;
    }
    const isError = validation(payload, setValidationForm);
    if (isError) {
      setLoadingForm(false);
      return;
    }
    if (dataTransactionPoint !== null) {
      methodFunction = updateTransactionPoint(dataTransactionPoint?.id, payload);
      titleMessageSuccess = "Update Transaksi Berhasil";
      captionMessageSuccess = "Anda telah berhasil mengupdate data Transaksi";
      titleMessageError = "Gagal Mengupdate Transaksi";
      captionMessageError = "Silahkan cek kembali form anda";
    } else {
      methodFunction = addTransactionPoint(payload);
      titleMessageSuccess = "Tambah Transaksi Berhasil";
      captionMessageSuccess = "Anda telah berhasil menambahkan Transaksi baru";
      titleMessageError = "Gagal Menambahkan Transaksi";
      captionMessageError = "Silahkan cek kembali form anda";
    }
    try {
      const response = await methodFunction;
      if (response) {
        onCloseModal();
        transactionPointList();
        notificationSuccess(titleMessageSuccess, captionMessageSuccess);
      }
    } catch (error) {
      const errorMessage = error.response.data.message;
      notificationError(titleMessageError, `${Object.keys(errorMessage) ? errorMessage : captionMessageError}`);
      Object.values(errorMessage).forEach((el) => {
        Object.keys(formValidation).forEach((element) => {
          if (el.includes(element)) {
            setValidationForm((old) => ({
              ...old,
              [element]: {
                ...old?.[element],
                isError: true,
                message: el,
              },
            }));
          }
        });
      });
    } finally {
      setLoadingForm(false);
    }
  };

  return (
    <Box p={isSmallScreen ? "sm" : 0} style={{ position: "relative", paddingBottom: "60px" }}>
      <Box pos="relative">
        <LoadingOverlay visible={loadingForm} zIndex={1000} overlayProps={{ radius: "sm", blur: 2 }} />
        <Box mb={70}>
          <Box mb="md">
            <Autocomplete
              mr={8}
              name="phoneNumber"
              label="No Handphone"
              renderOption={renderAutocompleteOption}
              withAsterisk
              placeholder="Search nomor handphone customer"
              searchable
              nothingFoundMessage="nomor handphone tidak ditemukan"
              data={customerList}
              onChange={handleCustomerChange}
              value={selectedCustomerId}
              withScrollArea={false}
            />
          </Box>
          {selectedcustomerData !== null && selectedcustomerData !== undefined && (
            <Box mb="md" mx="sm">
              <Text fz={14} fw="bold" mb="sm">
                Customer Info
              </Text>
              <FormInfo label="Name" value={`:  ${selectedcustomerData?.name}`} />
              <FormInfo label="Email" value={`:  ${selectedcustomerData?.email}`} />
            </Box>
          )}
          <Box mb="md">
            <TextInput
              disabled={selectedcustomerData === null || selectedcustomerData === undefined ? true : false}
              radius="sm"
              name="transactionCode"
              value={formData.transactionCode || ""}
              label="Kode Transaksi"
              placeholder="Masukkan kode transaksi"
              error={validationForm.transactionCode.isError ? `${validationForm.transactionCode.message}` : ""}
              onChange={handleChange}
            />
          </Box>
          <Box mb="md">
            <TextInput
              disabled={selectedcustomerData === null || selectedcustomerData === undefined ? true : false}
              component={NumericFormatCustom}
              radius="sm"
              name="nominalAmount"
              value={formData.nominalAmount}
              label="Jumlah Nominal"
              placeholder="Masukkan jumlah nominal"
              error={validationForm.nominalAmount.isError ? `${validationForm.nominalAmount.message}` : ""}
              onChange={handleChange}
              withAsterisk
            />
          </Box>
          <Box mb="md">
            <DatePickerInput
              disabled={selectedcustomerData === null || selectedcustomerData === undefined ? true : false}
              defaultValue={new Date()}
              value={formData.transactionDate}
              valueFormat="DD MMM YYYY"
              name="transactionDate"
              placeholder="Tanggal transaksi"
              label="Tanggal Transaksi"
              onChange={(value) => handleDateChange("transactionDate", value)}
              withAsterisk
            />
          </Box>
        </Box>
      </Box>

      <Box style={{ position: "fixed", bottom: 0, left: 0, width: "100%", background: "white", padding: "20px", zIndex: 1000 }}>
        <Flex justify="flex-end">
          <Group>
            <Button variant="outline" color="#152766" onClick={onCloseModal}>
              Tutup
            </Button>
            <Button variant="filled" color="#152766" onClick={() => handleSubmit(formData)} loading={loadingForm}>
              Simpan
            </Button>
          </Group>
        </Flex>
      </Box>
    </Box>
  );
};

export default FormTransaction;
