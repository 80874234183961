import React from "react";
import { Table, Text } from "@mantine/core";
import { CurrencyFormat, formatedTime, parseDateLocal } from "../../../plugins/helper";

const TableTransaksi = ({ label, data }) => {
  const mappingTableHead = (data) =>
    data.map((val, index) => {
      return (
        <Table.Th key={index} width={val.width !== "auto" ? val.width : ""}>
          {val.label}
        </Table.Th>
      );
    });

  const mappingDataTable = (data) => {
    console.log(data);
    if (data !== null) {
      return data?.map((val) => {
        return (
          <Table.Tr key={val.id}>
            <Table.Td>
              <Text fz={12}>{parseDateLocal(val.createdAt)}</Text>
              <Text fz={12} c="grey">
                {formatedTime(val.createdAt)} WIB
              </Text>{" "}
            </Table.Td>
            <Table.Td>{val?.transactionCode || "-"}</Table.Td>
            <Table.Td>{CurrencyFormat(val.nominalAmount)}</Table.Td>
          </Table.Tr>
        );
      });
    }
  };

  return (
    <Table.ScrollContainer minWidth={768}>
      <Table highlightOnHover horizontalSpacing="sm" style={{ fontSize: "13px", backgroundColor: "white", borderRadius: "10px " }}>
        <Table.Thead>
          <Table.Tr>{mappingTableHead(label)}</Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {data?.length !== 0 ? (
            <>{mappingDataTable(data)}</>
          ) : (
            <Table.Tr>
              <Table.Td></Table.Td>
              <Table.Td></Table.Td>
              <Table.Td py="lg">Belum ada data</Table.Td>
              <Table.Td></Table.Td>
              <Table.Td></Table.Td>
            </Table.Tr>
          )}
        </Table.Tbody>
      </Table>
    </Table.ScrollContainer>
  );
};

export default TableTransaksi;
