import http from "../plugins/axios";

export const getListGift = async (params) => {
  try {
    const response = await http.get(`/gift`, { params });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getDetailGift = async (id) => {
  try {
    const response = await http.get(`/gift/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const addGift = async (payload) => {
  try {
    const response = await http.post(`/gift`, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateGift = async (id, payload) => {
  try {
    const response = await http.patch(`/gift/update/${id}`, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};
