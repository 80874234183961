import React, { useEffect } from "react";
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { routes, routesAuth, routesAuthVerification, routesAuthCustomer } from "./route";

import { MantineProvider } from "@mantine/core";
import { Notifications } from "@mantine/notifications";
import { ModalsProvider } from "@mantine/modals";

// css mantine
import "@mantine/core/styles.css";
import "@mantine/notifications/styles.css";
import "@mantine/dates/styles.css";
import { getPermissionRole } from "./services/auth";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "universal-cookie";
import { updateProfile } from "./store/authData";
import { updatedRoute } from "./store/permissionRoute";
import http from "./plugins/axios";

function App() {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const cookies = new Cookies();
  const navigate = useNavigate();
  const isLogin = useSelector((state) => state.auth.isLogin);
  // console.log(isLogin);

  // const location = useLocation().pathname;
  // console.log(routeName);
  // console.log(pathname);

  const styleUI = {
    fontFamily: "Montserrat, sans-serif",
    headings: {
      fontFamily: "Montserrat, sans-serif",
    },
  };

  const getRoute = (allRoutes) =>
    allRoutes.map((page) => {
      if (!page.hasChildren) {
        return <Route path={page.route} element={page.component} key={page.name} />;
      } else {
        return (
          <Route path={page.route} key={page.name}>
            {childrenRoute(page.children)}
          </Route>
        );
      }
    });

  const childrenRoute = (arrayRoute) =>
    arrayRoute.map((children) => {
      if (children) {
        if (children.route === null) {
          return <Route index element={children.component} key={children.name} />;
        } else {
          return <Route path={children.route} element={children.component} key={children.name} />;
        }
      }
      return null;
    });

  const getHandlePermissionRole = async () => {
    try {
      const response = await getPermissionRole();
      dispatch(updatedRoute({ access: response }));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const authData = cookies.get("authData");
    const token = cookies.get("token_account_tandeem");
    if (token) {
      http.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      getHandlePermissionRole();
      dispatch(updateProfile({ dataUser: authData, isLogin: true }));

      const storedRoute = localStorage.getItem("currentRoute");
      if (storedRoute && storedRoute !== "/") {
        setTimeout(() => {
          navigate(storedRoute);
        }, 200);
      } else {
        localStorage.setItem("currentRoute", pathname);
      }
    }
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    localStorage.setItem("currentRoute", pathname);
    window.scrollTo(0, 0);
    //eslint-disable-next-line
  }, [pathname]);

  return (
    <MantineProvider theme={styleUI}>
      <Notifications autoClose={4000} position="top-right" limit={5} />
      <ModalsProvider>
        <Routes>
          {isLogin === false && pathname === "/" && (
            <>
              {getRoute(routes)}
              {getRoute(routesAuth)}
              {getRoute(routesAuthVerification)}
              {getRoute(routesAuthCustomer)}
              <Route element={<Navigate to="/authentication/sign-in" />} />
            </>
          )}
          {isLogin ? (
            <>
              {getRoute(routes)}
              <Route path="*" element={<Navigate to="/" />} />
            </>
          ) : (
            <>
              {getRoute(routesAuth)}
              {getRoute(routesAuthVerification)}
              {getRoute(routesAuthCustomer)}
              <Route element={<Navigate to="/authentication/sign-in" />} />
            </>
          )}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </ModalsProvider>
    </MantineProvider>
  );
}

export default App;
