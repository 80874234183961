import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Button, Flex, Group, NumberInput, Switch, Text, TextInput, Textarea, SimpleGrid, Image, rem, LoadingOverlay } from "@mantine/core";
import { Dropzone, IMAGE_MIME_TYPE } from "@mantine/dropzone";
import { IconCircleXFilled, IconPhoto, IconUpload, IconX } from "@tabler/icons-react";
import { useMediaQuery } from "@mantine/hooks";
import { validation } from "../../../plugins/validation";
import { addMemberLevel, updateMemberLevel } from "../../../services/memberLevel";
import { notificationError, notificationSuccess } from "../../ui/Notification";
import http from "../../../plugins/axios";

const defaultVal = {
  name: "",
  description: "",
  minimumPoint: "",
  logo: "",
  isActive: false,
};

const formValidation = {
  name: {
    isError: false,
    message: "",
  },
  description: {
    isError: false,
    message: "",
  },
  minimumPoint: {
    isError: false,
    message: "",
  },
  logo: {
    isError: false,
    message: "",
  },
  isActive: {
    isError: false,
    message: "",
  },
};

const FormMemberLevel = ({ onCloseModal, dataMemberLevel, memberLevelList }) => {
  const isSmallScreen = useMediaQuery("(max-width: 625px)");
  const [formData, setFormData] = useState(defaultVal);
  const [validationForm, setValidationForm] = useState(formValidation);
  const [rejectFileMessage, setRejectFileMessage] = useState("");
  const [loadingForm, setLoadingForm] = useState(false);
  const [errorFile, setErrorFile] = useState("");

  useEffect(() => {
    if (dataMemberLevel !== null) {
      handleSetForm(dataMemberLevel);
    }
    // eslint-disable-next-line
  }, [dataMemberLevel]);

  const handleSetForm = async (dataMemberLevel) => {
    setLoadingForm(true);
    let file = null;
    if (dataMemberLevel?.logo !== null) {
      try {
        const response = await http.get(`/public/${dataMemberLevel?.logo}`, {
          responseType: "blob",
        });
        file = new File([response.data], dataMemberLevel?.logo);
      } catch (error) {
        console.log(error);
        setErrorFile(error.response.statusText);
      }
    }
    const dataDetail = {
      isActive: Boolean(dataMemberLevel?.isActive),
      name: dataMemberLevel.name,
      minimumPoint: dataMemberLevel.minimumPoint,
      phoneNumber: dataMemberLevel.phoneNumber,
      description: dataMemberLevel.description,
      logo: file !== null ? file : null,
    };
    setFormData(dataDetail);
    setLoadingForm(false);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleDrop = useCallback((files) => {
    if (files.length > 0) {
      setFormData((prevState) => ({ ...prevState, logo: files[0] }));
      setRejectFileMessage("");
    }
  }, []);

  const handleRejectFile = useCallback((files) => {
    const errMessage = files[0].errors[0].message;
    setRejectFileMessage(errMessage);
    setFormData((prevState) => ({ ...prevState, logo: "" }));
  }, []);

  const previews = useMemo(() => {
    if (formData.logo) {
      const imageUrl = URL.createObjectURL(formData.logo);
      return <Image key={imageUrl} src={imageUrl} onLoad={() => URL.revokeObjectURL(imageUrl)} alt="Preview" width={100} height={100} />;
    }
    return null;
  }, [formData.logo]);

  const handleSwitch = () => {
    setFormData({ ...formData, isActive: !formData.isActive });
  };

  const handleSubmit = async (data) => {
    let methodFunction = null;
    let titleMessageSuccess = "";
    let captionMessageSuccess = "";
    let titleMessageError = "";
    let captionMessageError = "";
    setLoadingForm(true);
    setValidationForm(formValidation);
    const payload = {
      name: data.name,
      description: data.description,
      minimumPoint: data.minimumPoint,
      logo: data.logo,
      isActive: Boolean(data.isActive),
    };
    const isError = validation(payload, setValidationForm);
    if (isError) {
      setLoadingForm(false);
      return;
    }
    if (dataMemberLevel !== null) {
      methodFunction = updateMemberLevel(dataMemberLevel?.id, payload);
      titleMessageSuccess = "Update Member Level Berhasil";
      captionMessageSuccess = "Anda telah berhasil mengupdate data Member Level";
      titleMessageError = "Gagal Mengupdate Member Level";
      captionMessageError = "Silahkan cek kembali form anda";
    } else {
      methodFunction = addMemberLevel(payload);
      titleMessageSuccess = "Tambah Member Level Berhasil";
      captionMessageSuccess = "Anda telah berhasil menambahkan Member Level baru";
      titleMessageError = "Gagal Menambahkan Member Level";
      captionMessageError = "Silahkan cek kembali form anda";
    }
    try {
      const response = await methodFunction;
      if (response) {
        onCloseModal();
        memberLevelList();
        notificationSuccess(titleMessageSuccess, captionMessageSuccess);
      }
    } catch (error) {
      const errorMessage = error.response.data.message;
      notificationError(titleMessageError, `${Object.keys(errorMessage) ? errorMessage : captionMessageError}`);
      Object.values(errorMessage).forEach((el) => {
        Object.keys(formValidation).forEach((element) => {
          if (el.includes(element)) {
            setValidationForm((old) => ({
              ...old,
              [element]: {
                ...old?.[element],
                isError: true,
                message: el,
              },
            }));
          }
        });
      });
    } finally {
      setLoadingForm(false);
    }
  };

  return (
    <Box p={isSmallScreen ? "sm" : 0} style={{ position: "relative", paddingBottom: "60px" }}>
      <Box pos="relative">
        <LoadingOverlay visible={loadingForm} zIndex={1000} overlayProps={{ radius: "sm", blur: 2 }} />
        <Box mb={70}>
          <Box mb="md">
            <Flex justify="flex-start">
              <Text fz={14} mx={3} mr={8}>
                Active :{" "}
              </Text>
              <Switch color="#152766" onLabel="YES" offLabel="NO" checked={formData.isActive} onChange={handleSwitch} />
            </Flex>
          </Box>
          <Box mb="md">
            <TextInput radius="sm" name="name" value={formData.name} label="Nama Level" placeholder="Masukkan nama level" error={validationForm.name.isError ? `${validationForm.name.message}` : ""} onChange={handleChange} withAsterisk />
          </Box>
          <Box mb="md">
            <NumberInput
              radius="sm"
              value={formData.minimumPoint}
              label="Minimum Point"
              placeholder="Masukkan minimum point"
              error={validationForm.minimumPoint.isError ? `${validationForm.minimumPoint.message}` : ""}
              onChange={(value) => handleChange({ target: { value, name: "minimumPoint" } })}
              withAsterisk
            />
          </Box>
          <Textarea
            radius="sm"
            mb="md"
            name="description"
            label="Deskripsi"
            placeholder="Masukkan deskripsi level"
            value={formData.description}
            error={validationForm.description.isError ? `${validationForm.description.message}` : ""}
            autosize
            minRows={3}
            maxRows={4}
            onChange={handleChange}
            withAsterisk
          />

          <Box mb="md">
            <Text fz={14} mx={3} mr={8}>
              Logo/Icon
            </Text>

            <Dropzone maxSize={1 * 1024 ** 2} accept={IMAGE_MIME_TYPE} onDrop={handleDrop} onReject={handleRejectFile} style={{ border: "2px dashed #ACB2B8", borderRadius: "10px", cursor: "pointer" }}>
              <Group justify="center" gap="xl" mih={120} style={{ pointerEvents: "none" }}>
                <Dropzone.Accept>
                  <IconUpload style={{ width: rem(52), height: rem(52), color: "var(--mantine-color-blue-6)" }} stroke={1.5} />
                </Dropzone.Accept>
                <Dropzone.Reject>
                  <IconX style={{ width: rem(52), height: rem(52), color: "var(--mantine-color-red-6)" }} stroke={1.5} />
                </Dropzone.Reject>
                <Dropzone.Idle>
                  <IconPhoto style={{ width: rem(52), height: rem(52), color: "var(--mantine-color-dimmed)" }} stroke={1.5} />
                </Dropzone.Idle>

                <div>
                  <Text size={isSmallScreen ? "sm" : "lg"} px={isSmallScreen ? "md" : "sm"} inline>
                    Seret gambar ke sini atau klik untuk memilih file
                  </Text>
                  <Text size={isSmallScreen ? "xs" : "sm"} px={isSmallScreen ? "md" : "sm"} c="dimmed" inline mt={isSmallScreen ? 4 : 7} mb={isSmallScreen ? 8 : 0}>
                    Lampirkan gambar, gambar tidak boleh melebihi 1MB
                  </Text>
                </div>
              </Group>
            </Dropzone>
            {rejectFileMessage !== "" && (
              <Text fz={14} mx={3} mr={8} c="red" mt="sm">
                {rejectFileMessage}
              </Text>
            )}
            {errorFile !== "" && formData.logo === null && (
              <Text fz={14} mx={3} mr={8} c="red" mt="sm">
                Image {errorFile}
              </Text>
            )}
            <SimpleGrid cols={{ base: 1, sm: 4 }} mt={formData.logo ? "lg" : 0}>
              <Box sx={{ position: "relative", display: "inline-block" }}>
                {previews}{" "}
                {formData.logo !== null && formData.logo !== "" && (
                  <IconCircleXFilled
                    size={24}
                    color="red"
                    style={{
                      position: "absolute",
                      bottom: "5.5rem",
                      left: "8rem",
                      cursor: "pointer",
                    }}
                    onClick={() => setFormData((prevState) => ({ ...prevState, logo: "" }))}
                  />
                )}
              </Box>
            </SimpleGrid>
          </Box>
        </Box>
      </Box>

      <Box style={{ position: "fixed", bottom: 0, left: 0, width: "100%", background: "white", padding: "20px", zIndex: 1000 }}>
        <Flex justify="flex-end">
          <Group>
            <Button variant="outline" color="#152766" onClick={onCloseModal}>
              Tutup
            </Button>
            <Button variant="filled" color="#152766" onClick={() => handleSubmit(formData)} loading={loadingForm}>
              Simpan
            </Button>
          </Group>
        </Flex>
      </Box>
    </Box>
  );
};

export default FormMemberLevel;
