import { configureStore } from "@reduxjs/toolkit";
import authData from "../store/authData";
import permissionRoute from "../store/permissionRoute";

export const store = configureStore({
  reducer: {
    auth: authData,
    permission: permissionRoute,
  },
});
