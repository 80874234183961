import React, { useState } from "react";
import classes from "./authenticationCustomer.module.css";
import { BackgroundImage, Box, Button, Flex, Group, PasswordInput, Popover, Progress, Select, Text, TextInput } from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import PasswordRequired from "../../component/ui/PasswordRequired";
import { IconCheck, IconRosetteDiscountCheck } from "@tabler/icons-react";
import { regisCustomer } from "../../services/customer";
import { validation } from "../../plugins/validation";
import { notificationError } from "../../component/ui/Notification";
import { useParams } from "react-router-dom";
import { parseDate } from "../../plugins/helper";

const defaultVal = {
  fullName: "",
  email: "",
  phone: "",
  password: "",
  confirmPassword: "",
  address: "",
  gender: "",
  dateOfBirth: null,
};

const formValidation = {
  fullName: {
    isError: false,
    message: "",
  },
  email: {
    isError: false,
    message: "",
  },
  phone: {
    isError: false,
    message: "",
  },
  password: {
    isError: false,
    message: "",
  },
  confirmPassword: {
    isError: false,
    message: "",
  },
  address: {
    isError: false,
    message: "",
  },
  gender: {
    isError: false,
    message: "",
  },
  dateOfBirth: {
    isError: false,
    message: "",
  },
};

const requirements = [
  { re: /[0-9]/, label: "Password harus mencantumkan angka" },
  { re: /[a-z]/, label: "Password harus mencantumkan huruf kecil" },
  { re: /[A-Z]/, label: "Password harus mencantumkan huruf besar" },
  { re: /[$&+,:;=?@#|'<>.^*()%!-]/, label: "Password harus mencantumkan simbol" },
];

function getStrength(password) {
  let multiplier = password?.length > 7 ? 0 : 1;

  requirements.forEach((requirement) => {
    if (!requirement.re.test(password)) {
      multiplier += 1;
    }
  });

  return Math.max(100 - (100 / (requirements.length + 1)) * multiplier, 10);
}

const AuthenticationCustomer = () => {
  //   const isMobile = useMediaQuery("(max-width: 430px)");
  const params = useParams();
  const [loadingForm, setLoadingForm] = useState(false);
  const [success, setSuccess] = useState(false);
  const [formData, setFormData] = useState(defaultVal);
  const [validationForm, setValidationForm] = useState(formValidation);
  const [popoverPassword, setPopoverPassword] = useState(false);
  const checkPassword = requirements.map((val, index) => <PasswordRequired key={index} label={val.label} meets={val.re.test(formData.password)} />);
  const strengthPassword = getStrength(formData?.password);
  const token = params.token;
  const orgDefaultOrg = "03aedcf3-cbd3-4231-919f-944cf8cc0649";
  const orgBlueVinyl = "be0b14e8-7909-4b94-93e7-ac2c0bae1287";
  console.log(params.token);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSelectChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };

  const progressBar = Array(4)
    .fill(0)
    .map((_, index) => {
      return (
        <Progress
          styles={{ section: { transitionDuration: "0ms" } }}
          value={Object.values(formData?.password).length > 0 && index === 0 ? 100 : strengthPassword >= ((index + 1) / 4) * 100 ? 100 : 0}
          color={strengthPassword > 80 ? "teal" : strengthPassword > 50 ? "yellow" : "red"}
          key={index}
          size={4}
        />
      );
    });

  const FormPassword = () => {
    // if (setPassword) {
    return (
      <Box>
        <Box mb="md">
          <Popover opened={popoverPassword} position="bottom" width="target" transitionProps={{ transition: "pop" }}>
            <Popover.Target>
              <Box onFocusCapture={() => setPopoverPassword(true)} onBlurCapture={() => setPopoverPassword(false)}>
                <PasswordInput
                  name="password"
                  value={formData.password}
                  placeholder="Masukkan password"
                  label="Password"
                  error={validationForm.password.isError ? `${validationForm.password.message}` : ""}
                  onChange={handleChange}
                  withAsterisk
                />
              </Box>
            </Popover.Target>
            <Popover.Dropdown>
              <Group gap={5} grow mt="xs" mb="md">
                {progressBar}
              </Group>
              <PasswordRequired label="Password harus lebih dari 7 karakter" meets={formData.password.length > 7} />
              {checkPassword}
            </Popover.Dropdown>
          </Popover>
        </Box>
        <Box>
          <PasswordInput
            disabled={formData.password !== "" ? false : true}
            value={formData.confirmPassword}
            name="confirmPassword"
            placeholder="Ulangi password anda"
            label="Konfirmasi Password"
            error={validationForm.confirmPassword.isError ? validationForm.confirmPassword.message : ""}
            onChange={handleChange}
            withAsterisk
          />
        </Box>
        {formData.confirmPassword && (
          <Box mb="md">
            {formData.password !== formData.confirmPassword ? (
              <Text fz={14} mx={3} mr={8} c="red">
                Password tidak cocok!
              </Text>
            ) : (
              <Flex>
                <Text fz={14} mx={3} mr={2} c="green">
                  Password cocok
                </Text>
                <Text fz={14} mx={3} mt={2} c="green">
                  <IconCheck size={18} color="green" />
                </Text>
              </Flex>
            )}
          </Box>
        )}
      </Box>
    );
    // }
  };

  const handleSubmit = async (data) => {
    let methodFunction = null;
    let titleMessageError = "";
    let captionMessageError = "";
    setLoadingForm(true);
    setValidationForm(formValidation);
    const payload = {
      organizationId: params.token,
      fullName: data.fullName,
      email: data.email,
      phone: data.phone,
      address: data.address,
      gender: data.gender === "Perempuan" ? "female" : "male",
      dateOfBirth: parseDate(data.dateOfBirth),
      password: data.password,
      confirmPassword: data.confirmPassword,
    };
    const isError = validation(payload, setValidationForm);
    if (isError) {
      setLoadingForm(false);
      return;
    }
    methodFunction = regisCustomer(payload);
    titleMessageError = "Registrasi Gagal";
    captionMessageError = "Silahkan cek kembali form anda";
    try {
      const response = await methodFunction;
      if (response) {
        setSuccess(true);
      }
    } catch (error) {
      const errorMessage = error.response.data.message;
      notificationError(titleMessageError, `${Object.keys(errorMessage) ? errorMessage : captionMessageError}`);
      Object.values(errorMessage).forEach((el) => {
        Object.keys(formValidation).forEach((element) => {
          if (el.includes(element)) {
            setValidationForm((old) => ({
              ...old,
              [element]: {
                ...old?.[element],
                isError: true,
                message: el,
              },
            }));
          }
        });
      });
    } finally {
      setLoadingForm(false);
    }
  };

  const customStylePage = {
    orgDefaultOrg: {
      title: "Registrasi",
      desc: "Isi informasi Anda untuk bergabung",
      buttonColor: "#976260",
      backgroundColor: "#4160cf",
      backgroundImage: "https://www.hashmicro.com/id/blog/wp-content/uploads/2022/09/transaksi-adalah.jpg",
      textColor: "white",
    },
    orgBlueVinyl: {
      title: "Selamat Datang!",
      desc: "Silahkan isi form untuk mendaftar",
      buttonColor: "#3d4494",
      backgroundColor: "#4160cf",
      backgroundImage: "https://prismalink.co.id/wp-content/uploads/2024/07/e-wallet-indonesia-1024x576.jpg",
      textColor: "black",
    },
  };

  return (
    <Box className={classes.body}>
      <Box className={classes.container}>
        {!success ? (
          <>
            <BackgroundImage src={token === orgDefaultOrg ? customStylePage.orgDefaultOrg.backgroundImage : token === orgBlueVinyl ? customStylePage.orgBlueVinyl.backgroundImage : ""} radius="sm">
              <Box mb={10} mt={30} style={{ width: "100%" }}>
                <Box mt={50}>
                  <Text fw={700} fz={24} c={token === orgDefaultOrg ? customStylePage.orgDefaultOrg.textColor : token === orgBlueVinyl ? customStylePage.orgBlueVinyl.textColor : "white"} align="center">
                    {token === orgDefaultOrg ? customStylePage.orgDefaultOrg.title : token === orgBlueVinyl ? customStylePage.orgBlueVinyl.title : "Registrasi"}
                  </Text>
                  <Text fw={700} fz={14} mb="md" c={token === orgDefaultOrg ? customStylePage.orgDefaultOrg.textColor : token === orgBlueVinyl ? customStylePage.orgBlueVinyl.textColor : "white"} align="center">
                    {token === orgDefaultOrg ? customStylePage.orgDefaultOrg.desc : token === orgBlueVinyl ? customStylePage.orgBlueVinyl.desc : "Silahkan mendaftar dengan form di bawah ini!"}
                  </Text>
                </Box>
                <Box className={classes.card} mb="md">
                  <Box mb="md">
                    <TextInput
                      radius="sm"
                      name="fullName"
                      value={formData.fullName}
                      label="Nama Lengkap"
                      placeholder="Masukkan nama lengkap"
                      error={validationForm.fullName.isError ? `${validationForm.fullName.message}` : ""}
                      onChange={handleChange}
                      withAsterisk
                      className={classes.input}
                    />
                  </Box>
                  <Box mb="md">
                    <TextInput radius="sm" name="email" value={formData.email} label="Email" placeholder="Masukkan email" error={validationForm.email.isError ? `${validationForm.email.message}` : ""} onChange={handleChange} withAsterisk />
                  </Box>
                  <Box mb="md">
                    <TextInput
                      radius="sm"
                      name="phone"
                      value={formData.phone}
                      label="No Telepon/handphone"
                      placeholder="Masukkan nomor handphone"
                      error={validationForm.phone.isError ? `${validationForm.phone.message}` : ""}
                      onChange={handleChange}
                      withAsterisk
                    />
                  </Box>

                  <Box mb="md">
                    <Select label="Gender" withAsterisk mr={8} name="gender" placeholder="Select gender" data={["Laki-laki", "Perempuan"]} onChange={(value) => handleSelectChange("gender", value)} value={formData.gender} />
                  </Box>
                  <Box mb="md">
                    <DatePickerInput valueFormat="DD MMM YYYY" name="dateOfBirth" placeholder="Tanggal lahir" label="Tanggal Lahir" onChange={(value) => handleSelectChange("dateOfBirth", value)} value={formData.dateOfBirth} withAsterisk />
                  </Box>
                  <Box mb="md">
                    <TextInput
                      radius="sm"
                      name="address"
                      value={formData.address}
                      label="Alamat"
                      placeholder="Masukkan alamat"
                      error={validationForm.address.isError ? `${validationForm.address.message}` : ""}
                      onChange={handleChange}
                      withAsterisk
                    />
                  </Box>
                  {FormPassword()}
                  <Box>
                    <Group grow>
                      <Button
                        className={classes.button}
                        onClick={() => handleSubmit(formData)}
                        loading={loadingForm}
                        component="a"
                        mt="md"
                        variant="filled"
                        radius="sm"
                        size="md"
                        color={token === orgDefaultOrg ? customStylePage.orgDefaultOrg.buttonColor : token === orgBlueVinyl ? customStylePage.orgBlueVinyl.buttonColor : ""}
                        type="submit"
                      >
                        Daftar
                      </Button>
                    </Group>
                  </Box>
                </Box>
              </Box>
            </BackgroundImage>
          </>
        ) : (
          <>
            <Box mt={40}>
              <Text fw={600} fz={24} c="white" align="center">
                <IconRosetteDiscountCheck size={58} color="white" />
              </Text>
              <Text fw={600} fz={24} c="white" align="center">
                Registrasi Sukses
              </Text>
              <Text fw={400} fz={12} c="white" align="center">
                Pendaftaran anggota berhasil
              </Text>
              <Text fw={400} fz={12} mb="md" c="white" align="center">
                Silahkan login dengan data yang terdaftar melalui aplikasi.
              </Text>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

export default AuthenticationCustomer;
