import React, { useEffect, useState } from "react";
import Sidebar from "../../ui/AppShell/sidebar";
import { Avatar, Box, Card, Flex, Grid, Skeleton, Text } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { useSelector } from "react-redux";
import CardValue from "./component/cardValue";
import { IconObjectScan, IconTicket, IconUser, IconWallet } from "@tabler/icons-react";
import { CurrencyFormat, numberWithCommas } from "../../../plugins/helper";
import CardValue2 from "./component/cardValue2";
import { LineChart } from "@mantine/charts";
import { getListDashboard } from "../../../services/dashboard";

const Dashboard = () => {
  const isSmallScreen = useMediaQuery("(max-width: 625px)");
  const dataUser = useSelector((state) => state.auth.dataUser);
  const [loading, setLoading] = useState(false);
  const [dashboardList, setDashboardList] = useState([]);
  const listPermission = useSelector((state) => state.permission.access);
  console.log(listPermission);
  console.log(dashboardList);

  const handleGetListDashboard = async () => {
    setLoading(true);
    try {
      const response = await getListDashboard();
      const dataDashboard = response;
      setDashboardList(dataDashboard);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (listPermission !== null) {
      handleGetListDashboard();
    }
    // eslint-disable-next-line
  }, [listPermission]);

  return (
    <Sidebar>
      <Box my={10}>
        <Card shadow="sm" padding="sm" radius="md" withBorder>
          <Box py={isSmallScreen ? "xs" : "lg"} px="md" style={{ display: "flex", flexDirection: isSmallScreen ? "column" : "row", justifyContent: "space-between" }}>
            <Flex>
              <Avatar size="xl" mt="xs" />
              <Box p="md">
                <Text fz={18} fw="bold">
                  {dataUser?.fullName}
                </Text>
                <Text fz={14}>{dataUser?.email}</Text>
                <Text fz={14}>{dataUser?.organization}</Text>
              </Box>
            </Flex>
          </Box>
        </Card>
      </Box>
      {!loading && listPermission !== null ? (
        <>
          {/* card value */}
          <Box my={10}>
            <Grid>
              <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
                <CardValue value={CurrencyFormat(dashboardList.pointTransactionCount)} title="Transaksi hari ini" icon={<IconWallet size={28} />} />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
                <CardValue value={dashboardList?.customerCount} title="Total customer" icon={<IconUser size={28} />} />
              </Grid.Col>
              <Grid.Col span={{ base: 6, md: 6, lg: 3 }}>
                <CardValue value={dashboardList?.pointCouponCount} title="Total kupon aktif" icon={<IconTicket size={28} />} />
              </Grid.Col>
              <Grid.Col span={{ base: 6, md: 6, lg: 3 }}>
                <CardValue value={dashboardList?.couponExchangeCount} title="Tukar kupon hari ini" icon={<IconObjectScan size={28} />} />
              </Grid.Col>
            </Grid>
          </Box>
          {/* chart */}
          <Box my={10}>
            <Grid>
              <Grid.Col span={{ base: 12, md: 3, lg: 3 }}>
                <CardValue2 value={dashboardList?.customerCountToday} title="Customer baru" />
                <CardValue2 value={dashboardList?.customerCountBirthday} title="Customer Ulang Tahun" />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 9, lg: 9 }}>
                <Card shadow="sm" padding="md" radius="md" withBorder mb="md">
                  <Text fz={18} fw="bold" mb="md">
                    Total Transaksi Harian
                  </Text>
                  <LineChart h={300} p="lg" data={dashboardList?.pointTransactionChart} dataKey="date" series={[{ name: "transaction", color: "indigo.6" }]} curveType="linear" valueFormatter={(value) => numberWithCommas(value)} />
                </Card>
              </Grid.Col>
            </Grid>
          </Box>
        </>
      ) : (
        <Box my={20}>
          <Grid>
            <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
              <Skeleton height={100} />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
              <Skeleton height={100} />
            </Grid.Col>
            <Grid.Col span={{ base: 6, md: 6, lg: 3 }}>
              <Skeleton height={100} />
            </Grid.Col>
            <Grid.Col span={{ base: 6, md: 6, lg: 3 }}>
              <Skeleton height={100} />
            </Grid.Col>
          </Grid>
        </Box>
      )}
    </Sidebar>
  );
};

export default Dashboard;
