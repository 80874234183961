import React, { useEffect, useState } from "react";
import classes from "./customer.module.css";
import Sidebar from "../../component/ui/AppShell/sidebar";
import { Box, Button, Card, Flex, Modal, Pagination, ScrollArea, Text, TextInput, rem } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { IconCopyPlus, IconSearch } from "@tabler/icons-react";
import TableSkeleton from "../../component/ui/TableSkeleton";
import TableCustomer from "../../component/pages/customer/TableCustomer";
import FormCustomer from "../../component/pages/customer/FormCustomer";
import { getBirthdayCustomer, getListCustomer } from "../../services/customer";
import { useNavigate } from "react-router-dom";
import TableCustomerBirthday from "../../component/pages/customer/TableCustomerBIrthday";
import FormVerification from "../../component/pages/customer/FormVerification";
import { TABLE_DEFINITION } from "../../plugins/labelTable";

const param = {
  skip: 0,
  take: 10,
  keywords: "",
  orderBy: "fullName",
  order: "ASC",
};

const paramBirthday = {
  skip: 0,
  take: 10,
  keywords: "",
  orderBy: "fullName",
  order: "ASC",
};

const Customer = () => {
  const isSmallScreen = useMediaQuery("(max-width: 625px)");
  const navigate = useNavigate();
  const [params, setParams] = useState(param);
  const [paramsBirthday, setParamsBirthday] = useState(paramBirthday);
  const [loading, setLoading] = useState(false);
  const [loadingBirthday, setLoadingBirthday] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openModalVerification, setOpenModalVerification] = useState(false);
  const [detailData, setDetailData] = useState(null);
  const [customerList, setCustomerList] = useState([]);
  const [customerBirthdayList, setCustomerBirthdayList] = useState([]);
  const [countBirthday, setCountBirthday] = useState(0);
  const [count, setCount] = useState(0);

  const handleGetListCustomer = async () => {
    setLoading(true);
    try {
      const response = await getListCustomer(params);
      const dataCustomer = response.data;
      setCustomerList(dataCustomer);
      setCount(response.count);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleGetListBirthdayCustomer = async () => {
    setLoadingBirthday(true);
    try {
      const response = await getBirthdayCustomer(paramsBirthday);
      const dataCustomer = response.data;
      setCustomerBirthdayList(dataCustomer);
      setCountBirthday(response.count);
    } catch (error) {
      console.log(error);
    }
    setLoadingBirthday(false);
  };

  useEffect(() => {
    handleGetListCustomer();
    // eslint-disable-next-line
  }, [params]);

  useEffect(() => {
    handleGetListBirthdayCustomer();
    // eslint-disable-next-line
  }, [paramsBirthday]);

  const onCloseModal = () => {
    setOpenModal(false);
    setDetailData(null);
  };

  const onCloseModalVerification = () => {
    setOpenModalVerification(false);
    setDetailData(null);
  };

  const handleSearchChange = (e) => {
    const params = {
      skip: 0,
      take: 10,
      keywords: e.target.value,
      orderBy: "fullName",
      order: "ASC",
    };
    setParams(params);
  };

  const handleChangePage = (e) => {
    const params = {
      skip: (e - 1) * 10,
      take: 10,
      keywords: "",
      orderBy: "fullName",
      order: "ASC",
    };
    setParams(params);
  };

  const handleSearchBirthday = (e) => {
    const params = {
      skip: 0,
      take: 10,
      keywords: e.target.value,
      orderBy: "fullName",
      order: "ASC",
    };
    setParamsBirthday(params);
  };

  const handleChangePageBirthday = (e) => {
    const params = {
      skip: (e - 1) * 10,
      take: 10,
      keywords: "",
      orderBy: "fullName",
      order: "ASC",
    };
    setParamsBirthday(params);
  };

  const actions = {
    edit: (val) => {
      setOpenModal(true);
      setDetailData(val);
    },
    detail: (val) => {
      navigate(`/customer/${val.id}`);
    },
    verification: (val) => {
      setOpenModalVerification(true);
      setDetailData(val);
    },
  };

  const handleAction = (val, type) => {
    return actions[type](val);
  };

  return (
    <Sidebar>
      <Box className={classes.body}>
        <Box py={isSmallScreen ? "xs" : "lg"} style={{ display: "flex", flexDirection: isSmallScreen ? "column" : "row", justifyContent: "space-between" }}>
          <Flex>
            <Text className={classes.titlePage} ml={isSmallScreen ? "0px" : "sm"}>
              Customer
            </Text>
          </Flex>
          <Flex px={isSmallScreen ? "0px" : "sm"} mt={isSmallScreen ? 10 : 0} style={{ display: "flex", flexDirection: isSmallScreen ? "column" : "row", justifyContent: "space-between" }}>
            <TextInput
              radius="md"
              mr={isSmallScreen ? "0px" : "sm"}
              mb={isSmallScreen ? "sm" : "0px"}
              fullWidth
              leftSectionPointerEvents="none"
              leftSection=<IconSearch style={{ width: rem(16), height: rem(16) }} />
              placeholder="Cari customer. . ."
              name="search"
              onChange={handleSearchChange}
            />
            <Button className={classes.button} radius="md" fullWidth={isSmallScreen} leftSection={<IconCopyPlus size={14} />} color="#152766" onClick={() => setOpenModal(true)}>
              Tambah Customer
            </Button>
          </Flex>
        </Box>

        <Box my={10}>
          {loading ? (
            <TableSkeleton total={5} />
          ) : (
            <>
              <Card shadow="sm" padding="sm" radius="md" withBorder>
                <TableCustomer label={TABLE_DEFINITION.customer} data={customerList} countData={params.skip} actionMethod={handleAction} />
              </Card>
            </>
          )}
        </Box>
        <Flex justify="end">
          <Pagination onChange={handleChangePage} total={Math.ceil(count / paramsBirthday.take) || 0} color="#152766" radius="lg" />
        </Flex>
        <Box my={10} mt={20}>
          <Card shadow="sm" padding="sm" radius="md" withBorder>
            <Box py={isSmallScreen ? "xs" : "lg"} style={{ display: "flex", flexDirection: isSmallScreen ? "column" : "row", justifyContent: "space-between" }}>
              <Flex>
                <Text ml={isSmallScreen ? "0px" : "sm"} fz={14} fw="bold">
                  Customer birthday
                </Text>
              </Flex>
              <Flex px={isSmallScreen ? "0px" : "sm"} mt={isSmallScreen ? 10 : 0} style={{ display: "flex", flexDirection: isSmallScreen ? "column" : "row", justifyContent: "space-between" }}>
                <TextInput
                  radius="md"
                  mr={isSmallScreen ? "0px" : "sm"}
                  mb={isSmallScreen ? "sm" : "0px"}
                  fullWidth
                  leftSectionPointerEvents="none"
                  leftSection=<IconSearch style={{ width: rem(16), height: rem(16) }} />
                  placeholder="Cari customer. . ."
                  name="search"
                  onChange={handleSearchBirthday}
                />
              </Flex>
            </Box>
            {loadingBirthday ? <TableSkeleton total={5} /> : <TableCustomerBirthday label={TABLE_DEFINITION.customerBirthday} data={customerBirthdayList} countData={paramsBirthday.skip} />}
          </Card>
        </Box>
        <Flex justify="end">
          <Pagination onChange={handleChangePageBirthday} total={Math.ceil(countBirthday / paramsBirthday.take) || 0} color="#152766" radius="lg" />
        </Flex>
      </Box>

      <Modal
        fullScreen={isSmallScreen}
        opened={openModal}
        onClose={onCloseModal}
        centered
        closeOnClickOutside={false}
        size="lg"
        title={
          <Text fw="Bold" p={isSmallScreen ? "sm" : 0}>
            Tambah Customer{" "}
          </Text>
        }
        scrollAreaComponent={ScrollArea.Autosize}
        withCloseButton={isSmallScreen ? true : false}
      >
        <FormCustomer onCloseModal={onCloseModal} dataCustomer={detailData} customerList={handleGetListCustomer} />
      </Modal>

      <Modal
        opened={openModalVerification}
        onClose={onCloseModalVerification}
        centered
        closeOnClickOutside={false}
        size="lg"
        title={
          <Text fw="Bold" p={isSmallScreen ? "sm" : 0}>
            Verifikasi Email
          </Text>
        }
        scrollAreaComponent={ScrollArea.Autosize}
        withCloseButton={isSmallScreen ? true : false}
      >
        <FormVerification onCloseModal={onCloseModalVerification} dataCustomer={detailData} customerList={handleGetListCustomer} />
      </Modal>
    </Sidebar>
  );
};

export default Customer;
