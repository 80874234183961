import React from "react";
import { ActionIcon, Badge, Menu, Table } from "@mantine/core";
import { IconDotsVertical, IconEye } from "@tabler/icons-react";
import { CurrencyFormat, numberWithCommas, parseDateLocal } from "../../../plugins/helper";

const ActionMenu = ({ handleAction }) => {
  return (
    <Menu width={200} shadow="sm" position="bottom-end" withArrow arrowPosition="center">
      <Menu.Target>
        <ActionIcon ml={10} mt={6} variant="transparent" size="sm" color="rgba(0, 0, 0, 1)">
          <IconDotsVertical stroke={1.5} />
        </ActionIcon>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Item leftSection={<IconEye stroke={1.5} size={18} />} onClick={() => handleAction("edit")} fw="600" fz={12}>
          Lihat Detail / Edit
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};

const TablePointConversion = ({ label, data, actionMethod }) => {
  const mappingTableHead = (data) =>
    data.map((val, index) => {
      return (
        <Table.Th key={index} width={val.width !== "auto" ? val.width : ""}>
          {val.label}
        </Table.Th>
      );
    });

  const mappingDataTable = (data) => {
    return data.map((val) => {
      return (
        <Table.Tr key={val.id}>
          <Table.Td>{parseDateLocal(val.createdAt)}</Table.Td>
          <Table.Td>{CurrencyFormat(val.nominalAmount)}</Table.Td>
          <Table.Td>{numberWithCommas(val.pointAmount)}</Table.Td>
          <Table.Td>
            <Badge size="md" radius="sm" variant="filled" color={val.isActive ? "green" : "red"}>
              {val.isActive ? "Active" : "Non Active"}
            </Badge>
          </Table.Td>
          <Table.Td>
            <ActionMenu handleAction={(type) => actionMethod(val, type)} />
          </Table.Td>
        </Table.Tr>
      );
    });
  };

  return (
    <Table.ScrollContainer minWidth={768}>
      <Table highlightOnHover horizontalSpacing="sm" style={{ fontSize: "13px", backgroundColor: "white", borderRadius: "10px " }}>
        <Table.Thead>
          <Table.Tr>{mappingTableHead(label)}</Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {data.length !== 0 ? (
            <>{mappingDataTable(data)}</>
          ) : (
            <Table.Tr>
              <Table.Td></Table.Td>
              <Table.Td></Table.Td>
              <Table.Td py="lg">Tidak ada data</Table.Td>
              <Table.Td></Table.Td>
              <Table.Td></Table.Td>
            </Table.Tr>
          )}
        </Table.Tbody>
      </Table>
    </Table.ScrollContainer>
  );
};

export default TablePointConversion;
