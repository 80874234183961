import React, { useEffect, useState } from "react";
import { useMediaQuery } from "@mantine/hooks";
import { Link, useLocation } from "react-router-dom";
import classes from "../customer.module.css";
import Sidebar from "../../../component/ui/AppShell/sidebar";
import Accordions from "../../../component/ui/Accordion";
import TextDetail from "../../../component/ui/TextDetail";
import TableSkeleton from "../../../component/ui/TableSkeleton";
import TableCouponHistory from "../../../component/pages/customer/TableCouponHistory";
import TableGiftHistory from "../../../component/pages/customer/TableGiftHistory";
import TableTransaksi from "../../../component/pages/customer/TableTransaksi";
import TablePointHistory from "../../../component/pages/customer/TablePointHistory";

// plugin & helper
import { numberWithCommas, parseDateLocal } from "../../../plugins/helper";
import { getListTransactionPointHIstory } from "../../../services/transactionPointHistory";
import { TABLE_DEFINITION } from "../../../plugins/labelTable";
import { getDetailCustomer } from "../../../services/customer";

// mantine ui
import { Box, Card, Flex, Pagination, SimpleGrid, Skeleton, Tabs, Text } from "@mantine/core";
import { IconArrowLeft } from "@tabler/icons-react";
import { getListTransactionPointCustomer } from "../../../services/transactionPoint";

const limit = 10;

const DetailCustomer = () => {
  const isSmallScreen = useMediaQuery("(max-width: 625px)");
  const route = useLocation().pathname.split("/").slice(1);
  const id = route[route.length - 1];
  const [loading, setLoading] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false);
  const [detailCustomer, setDetailCustomer] = useState(null);
  const [pages, setPages] = useState(1);
  const [count, setCount] = useState(0);
  const [pointHistory, setPointHistory] = useState(null);
  const [couponHistory, setCouponHistory] = useState(null);
  const [giftHistory, setGiftHistory] = useState(null);
  const [transaksiCustomer, setTransaksiCustomer] = useState(null);
  const [activeTab, setActiveTab] = useState("first");
  const pointMember = pointHistory?.[0]?.saldoAkhirPoin;
  const pointAcumulation = pointHistory?.[0]?.akumulasiPoin;
  console.log(transaksiCustomer);

  const handleGetTransaksiCustomer = async () => {
    setLoadingTable(true);
    try {
      const response = await getListTransactionPointCustomer(limit, (pages - 1) * limit, id, "");
      const data = response.data;
      setTransaksiCustomer(data);
      setCount(response.count);
    } catch (error) {
      console.log(error);
    }
    setLoadingTable(false);
  };

  const handleGetDetailCustomer = async () => {
    setLoading(true);
    try {
      const response = await getDetailCustomer(id);
      const dataDetailCustomer = response;
      setDetailCustomer(dataDetailCustomer);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleGetPointHistory = async () => {
    setLoadingTable(true);
    try {
      const response = await getListTransactionPointHIstory(limit, (pages - 1) * limit, id, "");
      const data = response.data;
      setPointHistory(data);
      setCount(response.count);
    } catch (error) {
      console.log(error);
    }
    setLoadingTable(false);
  };

  const handleGetCouponHistory = async () => {
    setLoadingTable(true);
    try {
      const response = await getListTransactionPointHIstory(limit, (pages - 1) * limit, id, "coupon");
      const data = response.data;
      setCouponHistory(data);
      setCount(response.count);
    } catch (error) {
      console.log(error);
    }
    setLoadingTable(false);
  };

  const handleGetGiftHistory = async () => {
    setLoadingTable(true);
    try {
      const response = await getListTransactionPointHIstory(limit, (pages - 1) * limit, id, "gift");
      const data = response.data;
      setGiftHistory(data);
      setCount(response.count);
    } catch (error) {
      console.log(error);
    }
    setLoadingTable(false);
  };

  useEffect(() => {
    if (activeTab === "first") {
      handleGetTransaksiCustomer();
    }
    if (activeTab === "second") {
      handleGetPointHistory();
    }
    if (activeTab === "third") {
      handleGetCouponHistory();
    }
    if (activeTab === "fourth") {
      handleGetGiftHistory();
    }
    // eslint-disable-next-line
  }, [pages, activeTab]);

  useEffect(() => {
    handleGetDetailCustomer();
    // eslint-disable-next-line
  }, []);

  const handleChangePage = (e) => {
    setPages(e);
  };

  return (
    <Sidebar>
      <Box className={classes.body}>
        <Box py={isSmallScreen ? "xs" : "lg"} style={{ display: "flex", flexDirection: isSmallScreen ? "column" : "row", justifyContent: "space-between" }}>
          <Flex>
            <Link to="/customer" className={classes.linkBackTitle} style={{ textDecoration: "none", color: "black" }}>
              <IconArrowLeft />
            </Link>

            <Text className={classes.titlePage} ml={isSmallScreen ? "0px" : "sm"}>
              Detail Customer
            </Text>
          </Flex>
        </Box>
        {loading !== true ? (
          <>
            <Box my={10}>
              <Accordions label={detailCustomer?.fullName} description={`${numberWithCommas(pointMember) || "0"} poin, ${detailCustomer?.memberLevel?.name.toUpperCase() || "belum ada level"} member`} style={{ width: "100%" }}>
                <SimpleGrid
                  cols={2}
                  mx={10}
                  spacing="lg"
                  breakpoints={[
                    { maxWidth: "lg", cols: 2, spacing: "sm" },
                    { maxWidth: "md", cols: 2, spacing: "sm" },
                    { maxWidth: "sm", cols: 1, spacing: "sm" },
                  ]}
                >
                  <Box
                    style={{
                      borderRight: isSmallScreen ? "0px solid #ededed" : "3px solid #ededed",
                      borderBottom: isSmallScreen ? "1px solid #ededed" : "0px solid #ededed",
                    }}
                  >
                    <TextDetail label={"Point Acumulation"} value={`${numberWithCommas(pointAcumulation) || "0"} Points`} />
                    <TextDetail label={"Nama Lengkap"} value={detailCustomer?.fullName} />
                    <TextDetail label={"Nomor Handphone"} value={detailCustomer?.phoneNumber} />
                    <TextDetail label={"Email"} value={detailCustomer?.email} />
                    <TextDetail label={"Tanggal lahir"} value={parseDateLocal(detailCustomer?.profile.dateOfBirth)} />
                    <TextDetail label={"Jenis Kelamin"} value={detailCustomer?.profile.gender === "female" ? "Perempuan" : "Laki-laki"} />
                    <TextDetail label={"Alamat"} value={detailCustomer?.profile.address} />
                  </Box>
                  <Box>
                    <TextDetail label={"Member Sejak"} value={parseDateLocal(detailCustomer?.createdAt)} />
                    <TextDetail label={"Status"} value={detailCustomer?.isActive === true ? "Aktif" : "Tidak Aktif"} />
                    <TextDetail label={"Riwayat Login"} value={detailCustomer?.lastLogin !== null ? parseDateLocal(detailCustomer?.lastLogin) : "-"} />
                    <TextDetail label={"Status Verifikasi"} value={detailCustomer?.verificationStatus === true ? "Terverifikasi" : "Belum terverifikasi"} />
                  </Box>
                </SimpleGrid>
              </Accordions>
            </Box>
            <Box my={20}>
              <Card shadow="sm" padding="sm" radius="md" withBorder>
                <Tabs value={activeTab} onChange={setActiveTab}>
                  <Tabs.List grow>
                    <Tabs.Tab value="first">Riwayat Transaksi</Tabs.Tab>
                    <Tabs.Tab value="second">Riwayat Poin</Tabs.Tab>
                    <Tabs.Tab value="third">Riwayat Kupon</Tabs.Tab>
                    <Tabs.Tab value="fourth">Riwayat Hadiah</Tabs.Tab>
                  </Tabs.List>
                  <Tabs.Panel value="first" activeTab={activeTab}>
                    <Box>
                      <Box my={10}>{loadingTable ? <TableSkeleton total={5} /> : <TableTransaksi label={TABLE_DEFINITION.transactionCustomer} data={transaksiCustomer} countData={pages} />}</Box>
                      <Flex justify="end">
                        <Pagination onChange={handleChangePage} total={Math.ceil(count / limit) || 0} color="#152766" radius="lg" />
                      </Flex>
                    </Box>
                  </Tabs.Panel>
                  <Tabs.Panel value="second" activeTab={activeTab}>
                    <Box>
                      <Box my={10}>{loadingTable ? <TableSkeleton total={5} /> : <TablePointHistory label={TABLE_DEFINITION.pointHistory} data={pointHistory} countData={pages} />}</Box>
                      <Flex justify="end">
                        <Pagination onChange={handleChangePage} total={Math.ceil(count / limit) || 0} color="#152766" radius="lg" />
                      </Flex>
                    </Box>
                  </Tabs.Panel>
                  <Tabs.Panel value="third" activeTab={activeTab}>
                    <Box>
                      <Box my={10}>{loadingTable ? <TableSkeleton total={5} /> : <TableCouponHistory label={TABLE_DEFINITION.couponHistory} data={couponHistory} countData={pages} />}</Box>
                      <Flex justify="end">
                        <Pagination onChange={handleChangePage} total={Math.ceil(count / limit) || 0} color="#152766" radius="lg" />
                      </Flex>
                    </Box>
                  </Tabs.Panel>
                  <Tabs.Panel value="fourth" activeTab={activeTab}>
                    <Box>
                      <Box my={10}>{loadingTable ? <TableSkeleton total={5} /> : <TableGiftHistory label={TABLE_DEFINITION.giftHistory} data={giftHistory} countData={pages} />}</Box>
                      <Flex justify="end">
                        <Pagination onChange={handleChangePage} total={Math.ceil(count / limit) || 0} color="#152766" radius="lg" />
                      </Flex>
                    </Box>{" "}
                  </Tabs.Panel>
                </Tabs>
              </Card>
            </Box>
          </>
        ) : (
          <Skeleton height={80} mt={6} width="100%" />
        )}
      </Box>
    </Sidebar>
  );
};

export default DetailCustomer;
