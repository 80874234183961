import React, { useEffect, useState } from "react";
import { Box, Button, Flex, Group, LoadingOverlay, Select, Switch, Text, TextInput } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { YearPickerInput } from "@mantine/dates";
import { validation } from "../../../plugins/validation";
import { notificationError, notificationSuccess } from "../../ui/Notification";
import { addGift, updateGift } from "../../../services/gift";
import { getListCoupon } from "../../../services/coupon";
import { parseYear } from "../../../plugins/helper";

const defaultVal = {
  year: new Date(),
  type: "",
  pointCouponId: "",
  point: "",
  isActive: false,
};

const formValidation = {
  year: {
    isError: false,
    message: "",
  },
  type: {
    isError: false,
    message: "",
  },
  pointCouponId: {
    isError: false,
    message: "",
  },
  point: {
    isError: false,
    message: "",
  },
  isActive: {
    isError: false,
    message: "",
  },
};

const FormGift = ({ onCloseModal, dataGift, giftList }) => {
  const isSmallScreen = useMediaQuery("(max-width: 625px)");
  const [loadingForm, setLoadingForm] = useState(false);
  const [formData, setFormData] = useState(defaultVal);
  const [validationForm, setValidationForm] = useState(formValidation);
  const [selectedCouponId, setSelectedCouponId] = useState("");
  const [couponList, setCouponList] = useState([]);

  console.log(formData);

  const handleGetListCoupon = async () => {
    setLoadingForm(true);
    try {
      const response = await getListCoupon();
      const dataCoupon = response.data;
      const mappingCoupon = [
        { value: "", label: "Select kupon" },
        ...dataCoupon.map((val) => ({
          value: val?.id?.toString(),
          label: val?.name,
        })),
      ];
      setCouponList(mappingCoupon);
    } catch (error) {
      console.log(error);
    }
    setLoadingForm(false);
  };

  useEffect(() => {
    handleGetListCoupon();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (dataGift !== null) {
      handleSetForm(dataGift);
    }
    // eslint-disable-next-line
  }, [dataGift]);

  const handleSetForm = async (dataGift) => {
    setLoadingForm(true);
    const dataYear = new Date(dataGift.year);
    const tgl = new Date(dataGift.year).getFullYear();
    dataYear.setFullYear(tgl);
    const dataDetail = {
      year: dataYear,
      type: dataGift.type === "coupon" ? "Kupon" : "Poin",
      pointCouponId: selectedCouponId,
      point: dataGift.point,
      isActive: Boolean(dataGift?.isActive),
    };
    setSelectedCouponId(dataGift?.pointCoupon?.id.toString());
    setFormData(dataDetail);
    setLoadingForm(false);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleDateChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };

  const handleSwitchActive = () => {
    setFormData({ ...formData, isActive: !formData.isActive });
  };

  const handleSubmit = async (data) => {
    let methodFunction = null;
    let titleMessageSuccess = "";
    let captionMessageSuccess = "";
    let titleMessageError = "";
    let captionMessageError = "";
    setLoadingForm(true);
    setValidationForm(formValidation);
    const payload = {
      year: parseYear(data.year),
      type: data.type === "Kupon" ? "coupon" : "point",
      isActive: Boolean(data.isActive),
    };
    if (payload.type === "coupon") {
      payload.pointCouponId = selectedCouponId;
    }
    if (payload.type === "point") {
      payload.point = parseInt(data.point);
    }
    const isError = validation(payload, setValidationForm);
    if (isError) {
      setLoadingForm(false);
      return;
    }
    if (dataGift !== null) {
      methodFunction = updateGift(dataGift?.id, payload);
      titleMessageSuccess = "Update Hadiah Berhasil";
      captionMessageSuccess = "Anda telah berhasil mengupdate data Hadiah";
      titleMessageError = "Gagal Mengupdate Hadiah";
      captionMessageError = "Silahkan cek kembali form anda";
    } else {
      methodFunction = addGift(payload);
      titleMessageSuccess = "Tambah Hadiah Berhasil";
      captionMessageSuccess = "Anda telah berhasil menambahkan Hadiah baru";
      titleMessageError = "Gagal Menambahkan Hadiah";
      captionMessageError = "Silahkan cek kembali form anda";
    }
    try {
      const response = await methodFunction;
      if (response) {
        onCloseModal();
        giftList();
        notificationSuccess(titleMessageSuccess, captionMessageSuccess);
      }
    } catch (error) {
      const errorMessage = error.response.data.message;
      notificationError(titleMessageError, `${Object.keys(errorMessage) ? errorMessage : captionMessageError}`);
      Object.values(errorMessage).forEach((el) => {
        Object.keys(formValidation).forEach((element) => {
          if (el.includes(element)) {
            setValidationForm((old) => ({
              ...old,
              [element]: {
                ...old?.[element],
                isError: true,
                message: el,
              },
            }));
          }
        });
      });
    } finally {
      setLoadingForm(false);
    }
  };

  return (
    <Box p={isSmallScreen ? "sm" : 0} style={{ position: "relative", paddingBottom: "60px" }}>
      <Box pos="relative">
        <LoadingOverlay visible={loadingForm} zIndex={1000} overlayProps={{ radius: "sm", blur: 2 }} />
        <Box mb={70}>
          <Box mb="md">
            <YearPickerInput defaultValue={new Date()} value={formData.year} name="year" placeholder="Tahun" label="Tahun" onChange={(value) => handleDateChange("year", value)} withAsterisk />
          </Box>
          <Box mb="md">
            <Select label="Jenis" withAsterisk mr={8} name="type" placeholder="Select type" data={["Kupon", "Poin"]} onChange={(value) => handleDateChange("type", value)} value={formData.type} />
          </Box>
          {formData.type === "Kupon" ? (
            <Box mb="md">
              <Box mb="md">
                <Select
                  mr={8}
                  name="CouponId"
                  label="Kupon"
                  withAsterisk
                  placeholder="Select kupon"
                  searchable
                  nothingFoundMessage="Kupon tidak ditemukan"
                  data={couponList}
                  onChange={(e) => setSelectedCouponId(e)}
                  value={selectedCouponId}
                />
              </Box>
            </Box>
          ) : (
            <Box mb="md">
              <TextInput radius="sm" name="point" value={formData.point} label="Poin" placeholder="Masukkan poin" error={validationForm.point.isError ? `${validationForm.point.message}` : ""} onChange={handleChange} withAsterisk />
            </Box>
          )}
          <Box mb="md">
            <Flex justify="flex-start">
              <Text fz={14} mx={3} mr={8}>
                Active :{" "}
              </Text>
              <Switch color="#152766" onLabel="YES" offLabel="NO" checked={formData.isActive} onChange={handleSwitchActive} />
            </Flex>
          </Box>
        </Box>
      </Box>

      <Box style={{ position: "fixed", bottom: 0, left: 0, width: "100%", background: "white", padding: "20px", zIndex: 1000 }}>
        <Flex justify="flex-end">
          <Group>
            <Button variant="outline" color="#152766" onClick={onCloseModal}>
              Tutup
            </Button>
            <Button variant="filled" color="#152766" onClick={() => handleSubmit(formData)} loading={loadingForm}>
              Simpan
            </Button>
          </Group>
        </Flex>
      </Box>
    </Box>
  );
};

export default FormGift;
