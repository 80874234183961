import React from "react";
import { Table } from "@mantine/core";
import { parseDateLocal } from "../../../plugins/helper";

const TableCustomerBirthday = ({ label, data, countData }) => {
  const mappingTableHead = (data) =>
    data.map((val, index) => {
      return (
        <Table.Th key={index} width={val.width !== "auto" ? val.width : ""}>
          {val.label}
        </Table.Th>
      );
    });

  const mappingDataTable = (data) => {
    return data.map((val, index) => {
      return (
        <Table.Tr key={val.id}>
          <Table.Td>{index + countData + 1}.</Table.Td>
          <Table.Td>{val.customer_fullName}</Table.Td>
          <Table.Td>{val.customer_phoneNumber}</Table.Td>
          <Table.Td>{val.customer_email}</Table.Td>
          <Table.Td>{parseDateLocal(val.profile_createdAt)}</Table.Td>
          <Table.Td>{parseDateLocal(val.profile_dateOfBirth)}</Table.Td>
        </Table.Tr>
      );
    });
  };

  return (
    <Table.ScrollContainer minWidth={768}>
      <Table highlightOnHover horizontalSpacing="sm" style={{ fontSize: "13px", backgroundColor: "white", borderRadius: "10px " }}>
        <Table.Thead>
          <Table.Tr>{mappingTableHead(label)}</Table.Tr>
        </Table.Thead>
        <Table.Tbody>{mappingDataTable(data)}</Table.Tbody>
      </Table>
    </Table.ScrollContainer>
  );
};

export default TableCustomerBirthday;
