import React, { useEffect, useState } from "react";
import classes from "./authentication.module.css";
import { Badge, Box, Button, Card, Group, Text, rem } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { IconUserCircle } from "@tabler/icons-react";

import { updateProfile } from "../../store/authData.js";
import { updatedRoute } from "../../store/permissionRoute.js";
import { getPermissionRole } from "../../services/auth.js";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import http from "../../plugins/axios.js";
import Cookies from "universal-cookie";
// import CryptoJS from "crypto-js";

const Authentication = () => {
  const domain = window.location.hostname;
  const isMobile = useMediaQuery("(max-width: 430px)");
  const membershipId = "471aa0e7-16cb-4faf-a7eb-e55b4d22f2ef";
  const [errMessage, setErrMessage] = useState(null);
  // const cryptoKey = "30125c4f90b4b958";
  const cookies = new Cookies();
  const dispatch = useDispatch();
  const redirect = useNavigate();

  useEffect(() => {
    const authData = cookies.get("authData");
    const token = cookies.get("token_account_tandeem");
    if (token) {
      try {
        // ======= enabled when the token from sso can encrypt to cookies ============
        // const bytes = CryptoJS.AES.decrypt(token, cryptoKey);
        // const decrypted = bytes.toString(CryptoJS.enc.Utf8);
        // const dataLoggedIn = JSON.parse(decrypted);
        // ==========================================
        // const decodedToken = jwt_decode(dataLoggedIn);
        // console.log(decodedToken);
        http.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        getHandlePermissionRole();
        dispatch(updateProfile({ dataUser: authData, isLogin: true }));
        redirect("/dashboard");
      } catch (err) {
        console.log(err);
        setErrMessage(err?.res?.data?.message);
      }
    }
    //eslint-disable-next-line
  }, []);

  const getHandlePermissionRole = async () => {
    try {
      const response = await getPermissionRole();
      dispatch(updatedRoute({ access: response }));
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box className={classes.body}>
      <Box className={classes.card}>
        <Card shadow="lg" padding="lg" radius="md" w={isMobile ? 280 : 400}>
          <Box className={classes.logo}>
            <IconUserCircle size={44} />
          </Box>
          <Group className={classes.textGroup} mt="md" mb="xs" justify="center">
            <Text fw={800} fz={20} className={classes.closeText}>
              Membership Login
            </Text>
            <Text className={classes.closeText} fw={400} fz={14} mb="md">
              Silahkan login dengan tombol di bawah, Anda akan dialihkan ke halaman login.
            </Text>
          </Group>
          {errMessage && (
            <Box mb="sm" ta="center">
              <Badge size="md" variant="outline" color="red" radius="lg" fw={600} border={`${rem(1)} solid red`}>
                {errMessage}
              </Badge>
            </Box>
          )}
          <Box>
            <Box>
              <Group grow>
                <Button
                  className={classes.button}
                  component="a"
                  href={domain === "localhost" ? `http://localhost:3000/authentication/account/appId=${membershipId}` : `https://sso.tandeem.co.id/authentication/account/appId=${membershipId}`}
                  variant="filled"
                  radius="sm"
                  size="md"
                  color="#152766"
                  type="submit"
                >
                  Sign In
                </Button>
              </Group>
            </Box>
          </Box>
        </Card>
      </Box>
    </Box>
  );
};

export default Authentication;
