import { Box, Grid, Text } from "@mantine/core";
import React from "react";

const FormInfo = ({ label, value }) => {
  return (
    <Box>
      <Grid>
        <Grid.Col span={{ base: 2, md: 2 }}>
          <Text variant="p" c="dimmed" weight={500} size="sm" mt={0.5}>
            {label}
          </Text>
        </Grid.Col>
        <Grid.Col span={{ base: 6, md: 6 }}>
          <Box ml={0.5}>
            <Text mt={0.5} variant="p" c="dark" weight={500} size="sm">
              {value}
            </Text>
          </Box>
        </Grid.Col>
      </Grid>
    </Box>
  );
};

export default FormInfo;
