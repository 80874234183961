import React, { useEffect, useState } from "react";
import classes from "./exchangeCoupon.module.css";
import Sidebar from "../../component/ui/AppShell/sidebar";
import { Box, Button, Card, Flex, Grid, Group, Loader, Modal, Pagination, rem, ScrollArea, Select, Skeleton, Text, TextInput } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { IconCopyPlus, IconDownload, IconSearch } from "@tabler/icons-react";
import TableSkeleton from "../../component/ui/TableSkeleton";
import TableExchangeCoupon from "../../component/pages/exchangeCoupon/TableExchangeCoupon";
import FormExchangeCoupon from "../../component/pages/exchangeCoupon/FormExchangeCoupon";
import { TABLE_DEFINITION } from "../../plugins/labelTable";
import { downloadRedeemCoupon, getListRedeemCoupon } from "../../services/exchangeCoupon";
import { DatePickerInput } from "@mantine/dates";
import { getFirstDayOfMonth, getLastDayOfMonth, parseDate } from "../../plugins/helper";
import { getListCustomer } from "../../services/customer";
import { getListCoupon } from "../../services/coupon";

const defaultVal = {
  fromDate: getFirstDayOfMonth(),
  toDate: getLastDayOfMonth(),
  customerId: "",
  couponId: "",
};

const limit = 10;
const ExchangeCoupon = () => {
  const isSmallScreen = useMediaQuery("(max-width: 625px)");
  const [formData, setFormData] = useState(defaultVal);
  const [loading, setLoading] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [detailData, setDetailData] = useState(null);
  const [exchangeCouponList, setExchangeCouponList] = useState([]);
  const [count, setCount] = useState(0);
  const [pages, setPages] = useState(1);
  const [search, setSearch] = useState("");

  const [loadingForm, setLoadingForm] = useState(false);
  const [selectedCustomerId, setSelectedCustomerId] = useState("");
  const [customerList, setcustomerList] = useState([]);
  const [selectedCouponId, setSelectedCouponId] = useState("");
  const [couponList, setCouponList] = useState([]);

  const handleChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };

  const handleGetListCustomer = async () => {
    setLoadingForm(true);
    try {
      const response = await getListCustomer();
      const dataCustomer = response.data;
      const mappingCustomer = [
        { value: "", label: "Semua" },
        ...dataCustomer.map((val) => ({
          value: val?.id?.toString(),
          label: val?.fullName,
        })),
      ];
      setcustomerList(mappingCustomer);
    } catch (error) {
      console.log(error);
    }
    setLoadingForm(false);
  };

  const handleGetListCoupon = async () => {
    setLoadingForm(true);
    try {
      const response = await getListCoupon();
      const dataCoupon = response.data;
      const mappingCoupon = [
        { value: "", label: "Semua" },
        ...dataCoupon.map((val) => ({
          value: val?.id?.toString(),
          label: val?.name,
        })),
      ];
      setCouponList(mappingCoupon);
    } catch (error) {
      console.log(error);
    }
    setLoadingForm(false);
  };

  useEffect(() => {
    handleGetListCustomer();
    handleGetListCoupon();
    // eslint-disable-next-line
  }, []);

  const handleGetListExchangeCoupon = async () => {
    setLoading(true);
    try {
      const response = await getListRedeemCoupon(limit, (pages - 1) * limit, search, selectedCustomerId, selectedCouponId, parseDate(formData.fromDate), parseDate(formData.toDate));
      const dataExchangeCoupon = response.data;
      setExchangeCouponList(dataExchangeCoupon);
      setCount(response.count);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    handleGetListExchangeCoupon();
    // eslint-disable-next-line
  }, [pages, search]);

  const onClickFilter = () => {
    handleGetListExchangeCoupon();
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    setPages(1);
  };

  const handleChangePage = (e) => {
    setPages(e);
  };

  const onCloseModal = () => {
    setOpenModal(false);
    setDetailData(null);
  };

  const actions = {
    edit: (val) => {
      setOpenModal(true);
      setDetailData(val);
    },
  };

  const handleAction = (val, type) => {
    return actions[type](val);
  };

  const handleDownloadAll = async () => {
    setLoadingDownload(true);
    try {
      const response = await downloadRedeemCoupon(selectedCustomerId, selectedCouponId, parseDate(formData.fromDate), parseDate(formData.toDate));
      let fileName = `Laporan penukaran kupon ${parseDate(formData.fromDate)} - ${parseDate(formData.toDate)}`;
      const type = response?.type;
      const blob = new Blob([response], {
        type: type,
      });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      link.click();
    } catch (error) {
      console.log(error);
    }
    setLoadingDownload(false);
  };

  return (
    <Sidebar>
      <Box className={classes.body}>
        <Box py={isSmallScreen ? "xs" : "lg"} style={{ display: "flex", flexDirection: isSmallScreen ? "column" : "row", justifyContent: "space-between" }}>
          <Flex>
            <Text className={classes.titlePage} ml={isSmallScreen ? "0px" : "sm"}>
              Tukar Kupon
            </Text>
          </Flex>
          <Flex px={isSmallScreen ? "0px" : "sm"} mt={isSmallScreen ? 10 : 0} style={{ display: "flex", flexDirection: isSmallScreen ? "column" : "row", justifyContent: "space-between" }}>
            <TextInput
              radius="md"
              mr={isSmallScreen ? "0px" : "sm"}
              mb={isSmallScreen ? "sm" : "0px"}
              fullWidth
              leftSectionPointerEvents="none"
              leftSection=<IconSearch style={{ width: rem(16), height: rem(16) }} />
              placeholder="Cari kupon/customer. . ."
              name="search"
              onChange={handleSearchChange}
            />
            <Button className={classes.button} radius="md" fullWidth={isSmallScreen} leftSection={<IconCopyPlus size={14} />} color="#152766" onClick={() => setOpenModal(true)}>
              Tukar Kupon
            </Button>
          </Flex>
        </Box>

        <Box>
          <Card shadow="sm" padding="sm" radius="md" withBorder>
            {!loadingForm ? (
              <Box mb="md" mt="sm" px="sm">
                <Grid>
                  <Grid.Col span={{ base: 6, md: 3 }}>
                    <DatePickerInput value={formData.fromDate} valueFormat="DD MMM YYYY" name="fromDate" placeholder="Tanggal dimulai" label="Tanggal Dimulai" onChange={(value) => handleChange("fromDate", value)} />
                  </Grid.Col>
                  <Grid.Col span={{ base: 6, md: 3 }}>
                    <DatePickerInput value={formData.toDate} valueFormat="DD MMM YYYY" name="toDate" placeholder="Tanggal berakhir" label="Tanggal Berakhir" onChange={(value) => handleChange("toDate", value)} />
                  </Grid.Col>
                  <Grid.Col span={{ base: 6, md: 3 }}>
                    <Select
                      mr={8}
                      name="CustomerId"
                      label="Customer"
                      placeholder="Select Customer"
                      searchable
                      nothingFoundMessage="Customer tidak ditemukan"
                      data={customerList}
                      onChange={(e) => setSelectedCustomerId(e)}
                      value={selectedCustomerId}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ base: 6, md: 3 }}>
                    <Select mr={8} name="CouponId" label="Kupon" placeholder="Select Kupon" searchable nothingFoundMessage="Kupon tidak ditemukan" data={couponList} onChange={(e) => setSelectedCouponId(e)} value={selectedCouponId} />
                  </Grid.Col>
                </Grid>
              </Box>
            ) : (
              <Skeleton height={50} mb="md" />
            )}

            <Box mb="sm">
              <Flex justify="center">
                <Group>
                  <Button size="compact-md" fz={13} radius="sm" leftSection={<IconSearch size={14} />} color="#152766" onClick={onClickFilter}>
                    Filter
                  </Button>
                  <Button size="compact-md" fz={13} leftSection={<IconDownload size={14} />} color="teal" onClick={handleDownloadAll}>
                    {loadingDownload ? <Loader color="white" size="xs" /> : "Download Excel"}
                  </Button>
                </Group>
              </Flex>
            </Box>
          </Card>
        </Box>
        <Box my={10}>
          {loading ? (
            <TableSkeleton total={5} />
          ) : (
            <Card shadow="sm" padding="sm" radius="md" withBorder>
              <TableExchangeCoupon label={TABLE_DEFINITION.exchangeCoupon} data={exchangeCouponList} countData={count} actionMethod={handleAction} />
            </Card>
          )}
        </Box>
        <Flex justify="end">
          <Pagination onChange={handleChangePage} total={Math.ceil(count / limit) || 0} color="#152766" radius="lg" />
        </Flex>
      </Box>

      <Modal
        fullScreen={isSmallScreen}
        opened={openModal}
        onClose={onCloseModal}
        centered
        closeOnClickOutside={false}
        size="xl"
        title={
          <Text fw="Bold" p={isSmallScreen ? "sm" : 0}>
            Tukar Kupon{" "}
          </Text>
        }
        scrollAreaComponent={ScrollArea.Autosize}
        withCloseButton={isSmallScreen ? true : false}
      >
        <FormExchangeCoupon onCloseModal={onCloseModal} dataExchangeCoupon={detailData} exchangeCouponList={handleGetListExchangeCoupon} />
      </Modal>
    </Sidebar>
  );
};

export default ExchangeCoupon;
