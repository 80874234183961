import React from "react";
import classes from "./header.module.css";
import Cookies from "universal-cookie";
import { Flex, Menu, Text, rem } from "@mantine/core";
import { IconChevronDown, IconExternalLink, IconUser } from "@tabler/icons-react";
// import { useDispatch } from "react-redux";
// import { updateProfile } from "../../../../store/authData";
import { logout } from "../../../../services/auth";
import { useNavigate } from "react-router-dom";

function UserMenu({ name }) {
  const cookies = new Cookies();
  // const dispatch = useDispatch();
  const navigate = useNavigate();
  const domain = window.location.hostname;
  // console.log(domain);

  const handleLogout = async () => {
    await logout().then((res) => {
      console.log(res);
      cookies.remove("authData", { path: "/", domain: domain === "localhost" ? "localhost" : "tandeem.co.id" });
      cookies.remove("token_account_tandeem", { path: "/", domain: domain === "localhost" ? "localhost" : "tandeem.co.id" });
      cookies.remove("refreshToken_account_tandeem", { path: "/", domain: domain === "localhost" ? "localhost" : "tandeem.co.id" });
      setTimeout(() => {
        cookies.remove("authData", { path: "/", domain: domain === "localhost" ? "localhost" : "tandeem.co.id" });
        cookies.remove("token_account_tandeem", { path: "/", domain: domain === "localhost" ? "localhost" : "tandeem.co.id" });
        cookies.remove("refreshToken_account_tandeem", { path: "/", domain: domain === "localhost" ? "localhost" : "tandeem.co.id" });
        window.location.href = "/authentication/sign-in";
      }, 200);
    });
  };
  return (
    <Menu width={200} shadow="md">
      <Menu.Target className={classes.menuLink}>
        <Flex ml={12} align="center" style={{ cursor: "pointer" }}>
          <Text fz={13} fw={700} c="#152766" mr="sm">
            {name}
          </Text>
          <span className={classes.linkIcon}>
            <IconChevronDown stroke={2.5} size={20} mt="md" />
          </span>
        </Flex>
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Item
          onClick={(event) => {
            event.preventDefault();
            navigate(`/profil`);
          }}
          leftSection={<IconUser style={{ width: rem(14), height: rem(14) }} />}
        >
          Profile
        </Menu.Item>
        <Menu.Item onClick={handleLogout} leftSection={<IconExternalLink style={{ width: rem(14), height: rem(14) }} />}>
          Logout
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
}

export default UserMenu;
