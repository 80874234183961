import { Accordion, Avatar, Box, Group, Text } from "@mantine/core";
import React from "react";

const Accordions = ({ image, label, description, children }) => {
  return (
    <Box radius="md" shadow="sm" padding="sm" style={{ width: "100%" }}>
      <Accordion chevronPosition="right" mx="auto" variant="contained" style={{ backgroundColor: "white", width: "100%" }}>
        <Accordion.Item value="item" key="item">
          <Accordion.Control>
            <Group wrap="nowrap">
              <Avatar src={image} radius="xl" size="lg" />
              <div>
                <Text>{label}</Text>
                <Text size="sm" c="dimmed" fw={400}>
                  {description}
                </Text>
              </div>
            </Group>
          </Accordion.Control>
          <Accordion.Panel>{children}</Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    </Box>
  );
};

export default Accordions;
