import React from "react";
import { Table, Text } from "@mantine/core";
import { formatedTime, numberWithCommas, parseDateLocal } from "../../../plugins/helper";

const TableGiftHistory = ({ label, data }) => {
  const mappingTableHead = (data) =>
    data.map((val, index) => {
      return (
        <Table.Th key={index} width={val.width !== "auto" ? val.width : ""}>
          {val.label}
        </Table.Th>
      );
    });

  const mappingDataTable = (data) => {
    console.log(data);
    if (data !== null) {
      return data?.map((val) => {
        return (
          <Table.Tr key={val.id}>
            <Table.Td>
              <Text fz={14} fw="bold">
                {val.tipe === "penambahan" ? "Bertambah" : "Berkurang"}
              </Text>
              <Text fz={12} c="grey">
                {val?.notes.charAt(0).toUpperCase() + val?.notes.slice(1)}
              </Text>
              <Text fz={12} c="grey">
                {parseDateLocal(val.createdAt)} {formatedTime(val.createdAt)}
              </Text>
            </Table.Td>
            <Table.Td>
              <Text fz={14} fw="bold">
                {val?.gift?.type !== "point" ? val?.gift?.pointCoupon?.code : `${val?.gift?.point} points`}{" "}
              </Text>
              <Text fz={12} c="grey">
                {val?.gift?.type !== "point" ? val?.gift?.pointCoupon?.name : ""}{" "}
              </Text>
            </Table.Td>
            <Table.Td>{numberWithCommas(val.saldoAwalPoin)}</Table.Td>
            <Table.Td>
              {" "}
              {val?.tipe === "penambahan" ? "+" : "-"} {numberWithCommas(val?.nominalPoin)}
            </Table.Td>
            <Table.Td>
              <Text fz={13} fw="bold">
                {numberWithCommas(val.saldoAkhirPoin)}
              </Text>
            </Table.Td>
          </Table.Tr>
        );
      });
    }
  };

  return (
    <Table.ScrollContainer minWidth={768}>
      <Table highlightOnHover horizontalSpacing="sm" style={{ fontSize: "13px", backgroundColor: "white", borderRadius: "10px " }}>
        <Table.Thead>
          <Table.Tr>{mappingTableHead(label)}</Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {data?.length !== 0 ? (
            <>{mappingDataTable(data)}</>
          ) : (
            <Table.Tr>
              <Table.Td></Table.Td>
              <Table.Td></Table.Td>
              <Table.Td py="lg">Belum ada data</Table.Td>
              <Table.Td></Table.Td>
              <Table.Td></Table.Td>
            </Table.Tr>
          )}
        </Table.Tbody>
      </Table>
    </Table.ScrollContainer>
  );
};

export default TableGiftHistory;
