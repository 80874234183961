import http from "../plugins/axios";

export const getListDashboard = async () => {
  try {
    const response = await http.get(`/dashboard/admin`, {});
    return response.data;
  } catch (error) {
    throw error;
  }
};
