import React, { useState } from "react";
import { useMediaQuery } from "@mantine/hooks";
import { Box, Button, Flex, Group, Text } from "@mantine/core";
import { verificationEmail } from "../../../services/customer";
import { notificationError, notificationSuccess } from "../../ui/Notification";

const FormVerification = ({ onCloseModal, dataCustomer, customerList }) => {
  const isSmallScreen = useMediaQuery("(max-width: 625px)");
  const [loading, setLoading] = useState(false);
  console.log(dataCustomer);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const response = await verificationEmail(dataCustomer.id);
      console.log(response);
      const Message = response.data.message;
      notificationSuccess(Message);
      onCloseModal();
      customerList();
    } catch (error) {
      console.log(error);
      const errorMessage = error.response.data.message;
      notificationError(errorMessage);
    }
    setLoading(false);
  };

  return (
    <Box p={isSmallScreen ? "sm" : 0} style={{ position: "relative", paddingBottom: "60px" }}>
      <Box mb={70}>
        <Box mb="md">
          <Text>Anda yakin ingin verifikasi email untuk customer ini?</Text>
        </Box>
      </Box>
      <Box style={{ position: "fixed", bottom: 0, left: 0, width: "100%", background: "white", padding: "20px", zIndex: 1000 }}>
        <Flex justify="flex-end">
          <Group>
            <Button variant="outline" color="#152766" onClick={onCloseModal}>
              Tutup
            </Button>
            <Button variant="filled" color="#152766" onClick={handleSubmit} loading={loading}>
              Ya
            </Button>
          </Group>
        </Flex>
      </Box>
    </Box>
  );
};

export default FormVerification;
