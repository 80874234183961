import React, { useEffect, useState } from "react";
import classes from "./kupon.module.css";
import Sidebar from "../../component/ui/AppShell/sidebar";
import { Box, Button, Card, Flex, Modal, Pagination, ScrollArea, Text, TextInput, rem } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { IconCopyPlus, IconSearch } from "@tabler/icons-react";
import TableSkeleton from "../../component/ui/TableSkeleton";
import TableKupon from "../../component/pages/kupon/TableKupon";
import FormKupon from "../../component/pages/kupon/FormKupon";
import { TABLE_DEFINITION } from "../../plugins/labelTable";
import { getListCoupon } from "../../services/coupon";
import FormDeleteKupon from "../../component/pages/kupon/FormDelete";

const param = {
  orderBy: "createdAt",
  order: "DESC",
  keywords: "",
  take: 10,
  skip: 0,
};

const KuponPoin = () => {
  const isSmallScreen = useMediaQuery("(max-width: 625px)");
  const [params, setParams] = useState(param);
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [detailData, setDetailData] = useState(null);
  const [couponList, setCouponList] = useState([]);
  const [count, setCount] = useState(0);

  const handleGetListCoupon = async () => {
    setLoading(true);
    try {
      const response = await getListCoupon(params);
      const dataCoupon = response.data;
      setCouponList(dataCoupon);
      setCount(response.count);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    handleGetListCoupon();
    // eslint-disable-next-line
  }, [params]);

  const handleSearchChange = (e) => {
    const params = {
      orderBy: "createdAt",
      order: "DESC",
      keywords: e.target.value,
      take: 10,
      skip: 0,
    };
    setParams(params);
  };

  const handleChangePage = (e) => {
    const params = {
      orderBy: "createdAt",
      order: "DESC",
      keywords: "",
      take: 10,
      skip: (e - 1) * 10,
    };
    setParams(params);
  };

  const onCloseModal = () => {
    setOpenModal(false);
    setDetailData(null);
  };

  const onCloseModalDelete = () => {
    setOpenModalDelete(false);
    setDetailData(null);
  };

  const actions = {
    edit: (val) => {
      setOpenModal(true);
      setDetailData(val);
    },
    delete: (val) => {
      setOpenModalDelete(true);
      setDetailData(val);
    },
  };

  const handleAction = (val, type) => {
    return actions[type](val);
  };

  return (
    <Sidebar>
      <Box className={classes.body}>
        <Box py={isSmallScreen ? "xs" : "lg"} style={{ display: "flex", flexDirection: isSmallScreen ? "column" : "row", justifyContent: "space-between" }}>
          <Flex>
            <Text className={classes.titlePage} ml={isSmallScreen ? "0px" : "sm"}>
              Kupon
            </Text>
          </Flex>
          <Flex px={isSmallScreen ? "0px" : "sm"} mt={isSmallScreen ? 10 : 0} style={{ display: "flex", flexDirection: isSmallScreen ? "column" : "row", justifyContent: "space-between" }}>
            <TextInput
              radius="md"
              mr={isSmallScreen ? "0px" : "sm"}
              mb={isSmallScreen ? "sm" : "0px"}
              fullWidth
              leftSectionPointerEvents="none"
              leftSection=<IconSearch style={{ width: rem(16), height: rem(16) }} />
              placeholder="Cari kupon. . ."
              name="search"
              onChange={handleSearchChange}
            />
            <Button className={classes.button} radius="md" fullWidth={isSmallScreen} leftSection={<IconCopyPlus size={14} />} color="#152766" onClick={() => setOpenModal(true)}>
              Tambah Kupon
            </Button>
          </Flex>
        </Box>
        <Box my={10}>
          {loading ? (
            <TableSkeleton total={5} />
          ) : (
            <Card shadow="sm" padding="sm" radius="md" withBorder>
              <TableKupon label={TABLE_DEFINITION.coupon} data={couponList} countData={params.skip} actionMethod={handleAction} />
            </Card>
          )}
        </Box>
        <Flex justify="end">
          <Pagination onChange={handleChangePage} total={Math.ceil(count / params.take) || 0} color="#152766" radius="lg" />
        </Flex>
      </Box>

      <Modal
        fullScreen={isSmallScreen}
        opened={openModal}
        onClose={onCloseModal}
        centered
        closeOnClickOutside={false}
        size="lg"
        title={
          <Text fw="Bold" p={isSmallScreen ? "sm" : 0}>
            {detailData ? "Edit Kupon" : "Tambah Kupon"}
          </Text>
        }
        scrollAreaComponent={ScrollArea.Autosize}
        withCloseButton={isSmallScreen ? true : false}
      >
        <FormKupon onCloseModal={onCloseModal} dataCoupon={detailData} couponList={handleGetListCoupon} />
      </Modal>
      <Modal
        fullScreen={isSmallScreen}
        opened={openModalDelete}
        onClose={onCloseModalDelete}
        centered
        closeOnClickOutside={false}
        size="lg"
        title={
          <Text fw="Bold" p={isSmallScreen ? "sm" : 0}>
            Hapus Kupon{" "}
          </Text>
        }
        scrollAreaComponent={ScrollArea.Autosize}
        withCloseButton={isSmallScreen ? true : false}
      >
        <FormDeleteKupon onCloseModal={onCloseModalDelete} dataCoupon={detailData} couponList={handleGetListCoupon} />
      </Modal>
    </Sidebar>
  );
};

export default KuponPoin;
