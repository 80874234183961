const TABLE_DEFINITION = {
  customer: [
    {
      label: "No.",
      width: 30,
    },
    {
      label: "Nama",
      width: "auto",
    },
    {
      label: "No Telepon",
      width: "auto",
    },
    {
      label: "Email",
      width: "auto",
    },
    {
      label: "Member Sejak",
      width: "auto",
    },
    {
      label: "Level",
      width: "auto",
    },
    {
      label: "Action",
      width: 100,
    },
  ],
  customerBirthday: [
    {
      label: "No.",
      width: 30,
    },
    {
      label: "Nama",
      width: "auto",
    },
    {
      label: "No Telepon",
      width: "auto",
    },
    {
      label: "Email",
      width: "auto",
    },
    {
      label: "Member Sejak",
      width: "auto",
    },
    {
      label: "Tgl Lahir",
      width: "auto",
    },
  ],
  pointHistory: [
    {
      label: "Riwayat",
      width: "auto",
    },
    {
      label: "Penukaran Poin",
      width: "auto",
    },
    {
      label: "Poin Awal",
      width: "auto",
    },
    {
      label: "Pemakaian Poin",
      width: "auto",
    },
    {
      label: "Total Poin",
      width: "auto",
    },
  ],
  couponHistory: [
    {
      label: "Riwayat Kupon",
      width: "auto",
    },
    {
      label: "Kupon",
      width: "auto",
    },
    {
      label: "Poin Awal",
      width: "auto",
    },
    {
      label: "Poin Digunakan",
      width: "auto",
    },
    {
      label: "Total Poin",
      width: "auto",
    },
  ],
  giftHistory: [
    {
      label: "Riwayat Hadiah",
      width: "auto",
    },
    {
      label: "Hadiah",
      width: "auto",
    },
    {
      label: "Poin Awal",
      width: "auto",
    },
    {
      label: "Poin Digunakan",
      width: "auto",
    },
    {
      label: "Total Poin",
      width: "auto",
    },
  ],
  memberLevel: [
    {
      label: "No.",
      width: 30,
    },
    {
      label: "Level Name",
      width: "auto",
    },
    {
      label: "Description",
      width: "auto",
    },
    {
      label: "Minimum point",
      width: "auto",
    },
    {
      label: "Status",
      width: "auto",
    },
    {
      label: "Action",
      width: 100,
    },
  ],
  pointConversion: [
    {
      label: "Tgl Dibuat",
      width: "auto",
    },
    {
      label: "Nominal Transaksi",
      width: "auto",
    },
    {
      label: "Poin didapatkan",
      width: "auto",
    },
    {
      label: "Status",
      width: "auto",
    },
    {
      label: "Action",
      width: 100,
    },
  ],
  coupon: [
    {
      label: "No.",
      width: 30,
    },
    {
      label: "Nama Kupon",
      width: "auto",
    },
    {
      label: "Kode Kupon",
      width: "auto",
    },
    {
      label: "Deskripsi",
      width: 60,
    },
    {
      label: "Durasi",
      width: "auto",
    },
    {
      label: "Poin Ditukarkan",
      width: "auto",
    },
    {
      label: "Level Member",
      width: "auto",
    },
    {
      label: "Sekali Pakai",
      width: "auto",
    },
    {
      label: "Kuota",
      width: "auto",
    },
    {
      label: "Dibeli",
      width: "auto",
    },
    {
      label: "Ditukar",
      width: "auto",
    },
    {
      label: "Status",
      width: "auto",
    },
    {
      label: "Tgl Dibuat",
      width: "auto",
    },
    {
      label: "Action",
      width: 100,
    },
  ],
  gift: [
    {
      label: "Tahun",
      width: "auto",
    },
    {
      label: "Jenis",
      width: "auto",
    },
    {
      label: "Hadiah",
      width: "auto",
    },
    {
      label: "Status",
      width: "auto",
    },
    {
      label: "Action",
      width: 100,
    },
  ],
  transactionPoint: [
    {
      label: "Tgl Transaksi",
      width: "auto",
    },
    {
      label: "Code Transaksi",
      width: "auto",
    },
    {
      label: "Customer",
      width: "auto",
    },
    {
      label: "Nominal",
      width: "auto",
    },
    {
      label: "Action",
      width: 100,
    },
  ],
  exchangeCoupon: [
    {
      label: "Kupon",
      width: "auto",
    },
    {
      label: "Kode",
      width: "auto",
    },
    {
      label: "Customer",
      width: "auto",
    },
    {
      label: "Status",
      width: "auto",
    },
    {
      label: "Waktu Scan",
      width: "auto",
    },
    {
      label: "Deskripsi",
      width: "auto",
    },
    {
      label: "Action",
      width: 100,
    },
  ],
  transactionCustomer: [
    {
      label: "Tgl Transaksi",
      width: "auto",
    },
    {
      label: "Code Transaksi",
      width: "auto",
    },
    {
      label: "Nominal",
      width: "auto",
    },
  ],
};

export { TABLE_DEFINITION };
