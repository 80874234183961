import http from "../plugins/axios";

export const getListRedeemCoupon = async (take, skip, keywords, customerId, pointCouponId, fromDate, toDate) => {
  try {
    const response = await http.get(
      `/coupon-exchange?orderBy=createdAt&order=DESC&take=${take || 10}&skip=${skip || 0}&keywords=${keywords || ""}&customerId=${customerId || ""}&pointCouponId=${pointCouponId || ""}&fromDate=${fromDate || ""}&toDate=${toDate || ""}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const downloadRedeemCoupon = async (customerId, pointCouponId, fromDate, toDate) => {
  try {
    const response = await http.get(`/coupon-exchange/download-all?customerId=${customerId || ""}&pointCouponId=${pointCouponId || ""}&fromDate=${fromDate || ""}&toDate=${toDate || ""}`, {
      responseType: "blob",
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const addRedeemCoupon = async (payload) => {
  try {
    const response = await http.post(`/coupon-exchange`, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const scanRedeemCoupon = async (payload) => {
  try {
    const response = await http.post(`/coupon-code-exchange/scan`, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};
