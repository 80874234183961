const MENU_PERMISSION = {
  dashboard: "dashboard",
  permission: "permission",
  customer: "customer",
  role: "role",
  conversionSetting: "conversion-setting",
  couponExchange: "coupon-exchange",
  gift: "gift",
  memberLevel: "member-level",
  pointCoupon: "point-coupon",
  transactionPoint: "transaction-point",
  system: "system",
  systemOrg: "system-org",
};

export { MENU_PERMISSION };
