import http from "../plugins/axios";

export const getListCustomer = async (params) => {
  try {
    const response = await http.get(`/customer`, { params });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getDetailCustomer = async (id) => {
  try {
    const response = await http.get(`/customer/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getBirthdayCustomer = async (params) => {
  try {
    const response = await http.get(`/customer/birthday`, { params });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const addCustomer = async (payload) => {
  try {
    const response = await http.post(`/customer`, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const regisCustomer = async (payload) => {
  try {
    const response = await http.post(`/customer/register`, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateCustomer = async (id, payload) => {
  try {
    const response = await http.patch(`/customer/update/${id}`, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const verificationEmail = async (id) => {
  try {
    const response = await http.patch(`/customer/verification-email/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const verificationCustomer = async (token) => {
  try {
    const response = await http.post(`/verification-email/verify/${token}`);
    return response;
  } catch (error) {
    throw error;
  }
};
