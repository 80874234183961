import React from "react";
import classes from "./header.module.css";
import { useSelector } from "react-redux";
import { useMediaQuery } from "@mantine/hooks";
import { Box, Burger, Flex, Group, Text } from "@mantine/core";
import UserMenu from "./userMenu";

const Header = ({ mobileToggle, desktopToggle, openedMobile, openedDesktop }) => {
  const dataUser = useSelector((state) => state.auth.dataUser);
  const isMobile = useMediaQuery("(max-width: 625px)");
  // console.log(dataUser);
  // console.log(isLogin);

  return (
    <header className={classes.root}>
      <Box className={classes.sectionHeader}>
        <Group gap="lg" align="center">
          <Text fw={700} p="md" mr="md" c="#152766">
            Membership Admin
          </Text>
          <Burger opened={openedMobile} onClick={mobileToggle} hiddenFrom="sm" size="sm" />
          <Burger opened={openedDesktop} onClick={desktopToggle} visibleFrom="sm" size={20} />
        </Group>
        <Box>
          <Flex align="center">
            {!isMobile && <UserMenu name={dataUser?.fullName} />}
            {/* <Avatar variant="light" src={null} radius="xl" size={45} color="indigo.9" /> */}
            {/* {!isMobile && (
              <Box ml={8}>
                <Text mt="xs" fw={700} fz={14} c="#152766">
                  {dataUser?.fullName}
                </Text>
                {dataUser?.role?.map((el) => (
                  <Text c="indigo.9" fz={12}>
                    {el?.name}, {el?.applicationRole?.[0]?.application?.name}
                  </Text>
                ))}
              </Box>
            )} */}
          </Flex>
        </Box>
      </Box>
    </header>
  );
};

export default Header;
